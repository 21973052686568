import { ColumnDef, ColumnMeta } from '@tanstack/react-table';
import { useState } from 'react';
import useNotificationService from '../../controllers/notification/service';
import { ResponseNotification } from '../../types/notification';
import { Badge } from '../atoms/Badge';
import { Button } from '../atoms/Button';
import {
	NotificationTypeBadge,
	NotificationTypeBadgeVariant,
} from '../molecules/NotificationTypeBadge';
import {
	BaseTable,
	FilterOptionsType,
	TypeRangeFilterData,
} from '../organisms/Table/BaseTable';
import DateFilterPanel, { Range } from '../specialized/DateFilterPanel';
import { ColumnStyleType } from '../specialized/TransactionTable/columns';
import dayjs from 'dayjs';
import { iconMap } from '../../theme/Icons';
import { EmptyPlaceholder } from '../atoms/EmptyPlaceholder';

const NotificationsPage: React.FC = () => {
	const { data } = useNotificationService();

	// const TransactionTimeRangeMap = {
	// 	[Range.today]: TransactionTimeRange.day,
	// 	[Range.month]: TransactionTimeRange.month,
	// 	[Range.week]: TransactionTimeRange.week,
	// 	[Range.custom]: null,
	// };
	const [selectedRange, setSelectedRange] = useState<Range>(Range.today);

	const handleFilterChange = (range: Range) => {
		setSelectedRange(range);
	};

	const columns: (ColumnDef<
		ResponseNotification,
		string | Record<string, string>
	> & {
		style?: ColumnStyleType;
		meta?: ColumnMeta<
			{
				filter?: FilterOptionsType<TypeRangeFilterData | string[]>;
			},
			unknown
		>;
	})[] = [
		{
			accessorKey: 'read',
			header: '',
			cell: (props) => (
				<div className="w-fit">
					{!props.getValue<boolean>() ? (
						<div className="bg-gradient-primary border border-primary rounded-full py-1 px-4">
							New
						</div>
					) : (
						<div className="text-sm">Read</div>
					)}
				</div>
			),
			size: 50,
		},
		{
			accessorKey: 'notification_type',
			header: '',
			cell: (props) => (
				<NotificationTypeBadge
					variant={props.getValue<NotificationTypeBadgeVariant>()}
				/>
			),
			size: 120,
		},
		{
			accessorKey: 'timestamp',
			header: '',
			cell: (props) => (
				<div className="flex gap-x-4">
					<div>
						{dayjs.unix(props.getValue<number>()).format('DD MMM YYYY')}
					</div>
					<div>{dayjs.unix(props.getValue<number>()).format('H:mm')}</div>
				</div>
			),
			size: 120,
		},
		{
			accessorKey: 'title',
			header: '',
			cell: (props) => <div>{props.getValue<string>()}</div>,
			size: 130,
		},
		{
			accessorKey: 'message',
			header: '',
			cell: (props) => (
				<div className="whitespace-pre-line">{props.getValue<string>()}</div>
			),
			size: 130,
		},
		{
			accessorKey: 'actions',
			header: '',
			size: 140,
			cell: () => {
				return (
					<div className="w-full h-[52px] flex items-center gap-x-2">
						<Button variant="outline">Accept</Button>
					</div>
				);
			},
		},
	];

	return (
		<>
			{data.notification?.notifications.length === 0 ? (
				<EmptyPlaceholder className="flex-1">
					<EmptyPlaceholder.Icon icon={iconMap.noBellShadow} size={100} />
					<EmptyPlaceholder.Description description="There is no notifications to show at the moment" />
				</EmptyPlaceholder>
			) : (
				<div className="px-3 w-full h-full flex flex-col gap-y-5">
					<div className="flex justify-between items-cemter">
						<DateFilterPanel
							selectedRange={selectedRange}
							onFilterChange={handleFilterChange}
						/>
						<div className="flex items-center gap-x-3 rounded-full px-2.5 py-1.5 bg-grey-300">
							<div className="flex gap-x-5 pr-10">
								<Badge className="items-center">
									<div className="capitalize">News</div>
								</Badge>
								<Badge className="items-center" variant="outline">
									<div className="capitalize">Read</div>
								</Badge>
							</div>

							<Badge className="items-center">
								<div className="capitalize">All</div>
							</Badge>
							<div className="flex  gap-x-3 items-center">
								<NotificationTypeBadge variant="security" />
								<NotificationTypeBadge variant="wallet" />
								<NotificationTypeBadge variant="transaction" />
								<NotificationTypeBadge variant="summary" />
							</div>
						</div>
					</div>
					<div className="grow overflow-y-auto">
						<BaseTable
							columns={columns}
							tableHeaderStyle="hidden"
							data={data.notification?.notifications || []}
							isLoading={false}
							error={null}
							enableExpanding={false}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default NotificationsPage;
