import ResponsiveIcon from '../../../atoms/Icon';
import { TSignInOption } from './SignInOptions';
import './sign-in-option.css';

interface SignInOptionProps {
	selectedOption: TSignInOption;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	value: TSignInOption;
	name: string;
	icon: any;
	label: string;
	description: string;
}

const SignInOption: React.FC<SignInOptionProps> = ({
	selectedOption,
	handleChange,
	value,
	name,
	icon,
	label,
	description,
}) => {
	return (
		<>
			<div className="flex items-center gap-3">
				<input
					type="radio"
					id={value}
					name={name}
					value={value}
					checked={selectedOption === value}
					onChange={handleChange}
					className="w-5 h-5 custom-radio appearance-none rounded-full border border-white custom-radio bg-white checked:bg-white checked:border-white relative"
				/>
				<ResponsiveIcon
					className="flex self-start h-[37.5px]"
					color="current"
					icon={icon}
				/>
				<div className="flex flex-col">
					<label
						htmlFor={value}
						className="text-xl font-400 truncate max-w-60 md:max-w-none"
					>
						{label}
					</label>
				</div>
			</div>
			<p className="text-sm border-b pb-2 pr-10 gap-8 ps-[66px] mb-10">
				<label htmlFor={value}>{description}</label>
			</p>
		</>
	);
};

export default SignInOption;
