import { ReactNode } from 'react';
import { IconType } from '../../types/common';
import { cn } from '../../libs/cn';

interface EmptyPlaceholderProps {
	children?: ReactNode;
	className?: string;
}

interface IconProps {
	icon: IconType;
	size?: number;
}

interface DescriptionProps {
	description?: string;
}

const EmptyPlaceholder = ({ children, className }: EmptyPlaceholderProps) => {
	return (
		<div
			className={cn(
				'w-full h-full flex flex-col items-center justify-center gap-y-4',
				className
			)}
		>
			{children}
		</div>
	);
};

const Icon = ({ icon: Icon, size }: IconProps) => {
	return <Icon size={size} />;
};

const Description = ({ description }: DescriptionProps) => {
	return (
		<div className="font-roboto text-lg font-normal text-grey-600">
			{description}
		</div>
	);
};

EmptyPlaceholder.Icon = Icon;
EmptyPlaceholder.Description = Description;

export { EmptyPlaceholder };
