import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { router } from './routes/router';
import { store } from './store/config.store';
import './index.css';

import 'react-datepicker/dist/react-datepicker.css';

import { createAppKit } from '@reown/appkit/react';

import { WagmiProvider } from 'wagmi';
import { arbitrum, mainnet } from '@reown/appkit/networks';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi';

const queryClient = new QueryClient();

const projectId = '2c079a0321f084d11daf7ed1e7f8df0f'; // move to env/config

const metadata = {
	name: 'Sailo',
	description: '',
	url: 'https://reown.com/appkit', // origin must match domain & subdomain
	icons: ['https://assets.reown.com/reown-profile-pic.png'],
};

const networks = [mainnet, arbitrum];

const wagmiAdapter = new WagmiAdapter({
	networks,
	projectId,
	ssr: true,
});

createAppKit({
	adapters: [wagmiAdapter],
	networks: networks as any,
	projectId,
	metadata,
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<WagmiProvider config={wagmiAdapter.wagmiConfig}>
			<QueryClientProvider client={queryClient}>
				<Provider store={store}>
					<RouterProvider router={router} />
				</Provider>
			</QueryClientProvider>
		</WagmiProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
