import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/hooks';
import {
	SendTransaction,
	sendTransactionsActions,
	SendTransactionsState,
} from './slice';
import { ResponseData } from '../../types/common';
import { ResponsePostTransaction } from '../../types/transaction';
import { store } from '../../store/config.store';
import { postTransactionApi } from './api';

type SendTransactionServiceOperators = SendTransactionsState & {
	addDraftTransaction: (data: SendTransaction) => Promise<void>;
	removeDraftTransaction: (id: string) => void;
	sendQuickTransaction: (
		data: SendTransaction
	) => Promise<ResponseData<ResponsePostTransaction>>;
	fetchMarketDataPrice: (fsym: string, tsyms: string) => void;
	fetchSupportedCoins: () => void;
	supportedCoinsList: string[];
};

const useSendTransactionsService =
	(): Readonly<SendTransactionServiceOperators> => {
		const dispatch = useDispatch();
		const sendTransactionState = useAppSelector(
			(state) => state.sendTransactions as SendTransactionsState
		);

		const addDraftTransaction = useCallback(
			async (data: SendTransaction) => {
				const response = await postTransactionApi([
					{
						...data,
						chain: data.coin,
					},
				]);

				const transaction = response[0];
				if (transaction) {
					dispatch(
						sendTransactionsActions.addTransaction({
							...transaction,
							id: transaction.response.transaction_id,
							addedDate: Date.now(),
						})
					);
				}
			},
			[dispatch]
		);

		const removeDraftTransaction = useCallback(
			(id: string) => {
				dispatch(sendTransactionsActions.removeTransaction(id));
			},
			[dispatch]
		);

		const sendQuickTransaction = useCallback(
			async (
				data: SendTransaction
			): Promise<ResponseData<ResponsePostTransaction>> => {
				return new Promise((resolve, reject) => {
					const subscription = store.subscribe(() => {
						const state = store.getState().sendTransactions;

						if (state.data.quickTransaction) {
							subscription();
							resolve(state.data.quickTransaction);
						}

						if (state.error.quickTransaction) {
							subscription();
							reject(new Error(state.error.quickTransaction));
						}
					});

					dispatch(sendTransactionsActions.sendQuickTransactionRequest(data));
				});
			},
			[dispatch]
		);
		const fetchMarketDataPrice = useCallback(
			(fsym: string, tsyms: string) => {
				dispatch(
					sendTransactionsActions.getMarketDataPriceRequest({ fsym, tsyms })
				);
			},
			[dispatch]
		);

		const fetchSupportedCoins = useCallback(() => {
			dispatch(sendTransactionsActions.getSupportedCoinsRequest());
		}, [dispatch]);

		useEffect(() => {
			if (
				!sendTransactionState.data.supportedCoins?.supported_coins?.length &&
				!sendTransactionState.loading.supportedCoins
			) {
				fetchSupportedCoins();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const supportedCoinsList = useMemo(() => {
			return sendTransactionState.data.supportedCoins?.supported_coins
				? sendTransactionState.data.supportedCoins?.supported_coins?.map(
						(coin) => coin.coin.toUpperCase().trim()
					)
				: [];
		}, [sendTransactionState.data.supportedCoins?.supported_coins]);

		return {
			data: sendTransactionState.data,
			loading: sendTransactionState.loading,
			error: sendTransactionState.error,
			addDraftTransaction,
			removeDraftTransaction,
			fetchMarketDataPrice,
			sendQuickTransaction,
			fetchSupportedCoins,
			supportedCoinsList,
		};
	};

export default useSendTransactionsService;
