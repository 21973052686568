import { cn } from '../../libs/cn';
import { Wallet } from '../../types/wallets';
import WalletListItem from '../molecules/WalletListItem';
import { riskLevelColorMap } from '../pages/wallets/WalletsPage';

interface WalletsListProps {
	data: Wallet[];
	className?: string;
	goToWalletDetails: (id: string) => void;
}

const WalletsList: React.FC<WalletsListProps> = ({
	data,
	className,
	goToWalletDetails,
}) => {
	return (
		<div className={cn('grid gap-4', className)}>
			{data?.map((wallet) => {
				const factorBg = riskLevelColorMap[wallet?.riskLevel] || 'bg-gray-100';

				return (
					<WalletListItem
						key={wallet?.id}
						item={wallet}
						bgColor={factorBg}
						goToWalletDetails={goToWalletDetails}
					/>
				);
			})}
		</div>
	);
};

export default WalletsList;
