import { forwardRef } from 'react';
import { cn } from '../../libs/cn';
import { IconType } from '../../types/common';

type IconProps = {
	icon: IconType;
	size?: number;
	color?: string;
	colors?: string[];
	className?: string;
	backgroundColor?: string;
	onClick?: (v: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	badgeText?: number | string;
	badgeStyles?: string;
};

const ResponsiveIcon = forwardRef<HTMLDivElement, IconProps>((props, ref) => {
	if (!props?.icon) {
		return null;
	}

	const {
		icon: Icon,
		color = 'currentColor',
		size,
		className,
		onClick,
		badgeText,
		badgeStyles,
		colors,
		...rest
	} = props;

	const classes = cn(
		'flex items-center justify-center relative',
		size && `w-${size} h-${size}`,
		className
	);

	return (
		<div className={classes} onClick={onClick} ref={ref} {...rest}>
			<Icon color={color} colors={colors} size={size} className={className} />

			{badgeText && (
				<div
					className={cn(
						'absolute top-[-2px] right-[-2px] text-white rounded-full w-4 h-4 flex items-center justify-center',
						badgeStyles
					)}
				>
					<span className="text-[11px]">{badgeText}</span>
				</div>
			)}
		</div>
	);
});
ResponsiveIcon.displayName = 'ResponsiveIcon';

export default ResponsiveIcon;
