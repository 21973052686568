const API_BASE_AUTH_URL = 'https://auth.dev.sailo.tech';
const API_BASE_TRANSACTION_URL = 'https://transaction.dev.sailo.tech';
const API_BASE_WALLET_URL = 'https://wallet.dev.sailo.tech';
const API_BASE_MEMBER_URL = 'https://member.dev.sailo.tech';
const SECRET_KEY = 'secret';

export {
	API_BASE_AUTH_URL,
	API_BASE_TRANSACTION_URL,
	SECRET_KEY,
	API_BASE_WALLET_URL,
	API_BASE_MEMBER_URL,
};
