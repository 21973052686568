import cryptojs from 'crypto-js';
import { SECRET_KEY } from '../config';

export const setSecureToken = (token: string, key: string) => {
	const encryptedToken = cryptojs.AES.encrypt(token, SECRET_KEY).toString();
	localStorage.setItem(key, encryptedToken);
};

export const getSecureToken = (key: string) => {
	const encryptedToken = localStorage.getItem(key);

	if (encryptedToken) {
		const token = cryptojs.AES.decrypt(encryptedToken, SECRET_KEY).toString(
			cryptojs.enc.Utf8
		);
		return token;
	}
	return null;
};
