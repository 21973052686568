import { useEffect, useState } from 'react';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';

import LoginForm, { formSchema } from '../../organisms/auth/Login/LoginForm';
import useAuthService from '../../../controllers/auth/service';
import SignInOptions, {
	TSignInOption,
} from '../../organisms/auth/Login/SignInOptions';

const LoginPage: React.FC = () => {
	const navigate = useNavigate();
	const {
		login,
		data: { isAuthenticated },
	} = useAuthService();
	const [credentials, setCredentials] = useState({ email: '', password: '' });

	const onCredentials = (values: z.infer<typeof formSchema>) => {
		const { email, password } = values;
		if (email && password) {
			setCredentials({ email, password });
		}
	};

	const onLoginFormSubmit = async (signInOption: TSignInOption) => {
		try {
			const { email, password } = credentials;
			if (signInOption === 'passkey') {
				// TODO: go to third party page
				return;
			}
			login(email, password, signInOption);
		} catch (error) {
			console.log('error', error);
		}
	};

	useEffect(() => {
		if (isAuthenticated) {
			navigate('/auth/verify-code');
		}
	}, [isAuthenticated, navigate]);

	const loginStep = (
		<>
			<div className="flex flex-col gap-3">
				<h2 className="text-5xl font-bold">Log In</h2>
				<div>
					<p className="leading-5">Get a verification code to access your</p>
					<p>SailoTech account</p>
				</div>
			</div>

			<LoginForm onCredentials={onCredentials} />
		</>
	);

	const optionsStep = (
		<>
			<div className="flex flex-col gap-3">
				<h2 className="text-5xl font-bold">Sign in options.</h2>
			</div>
			<SignInOptions onLoginFormSubmit={onLoginFormSubmit} />
		</>
	);

	return !(credentials.email && credentials.password) ? loginStep : optionsStep;
};

export default LoginPage;
