import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useWalletsService from '../../../controllers/wallets/service';
import { iconMap } from '../../../theme/Icons';
import { RiskLvl } from '../../../types/transaction';
import { Button } from '../../atoms/Button';
import ResponsiveIcon from '../../atoms/Icon';
import { Progress } from '../../atoms/Progress';
import {
	DisplayMode,
	DisplayModeSwitcher,
} from '../../molecules/DisplayModeSwitcher';
import WalletsGrid from '../../organisms/WalletsGrid';
import WalletsList from '../../organisms/WalletsList';
import { cn } from '../../../libs/cn';
import { NewWalletPopUp } from '../../molecules/NewWalletPopUp';

export const riskLevelColorMap = {
	[RiskLvl.critical]: 'bg-critical-100',
	[RiskLvl.medium]: 'bg-medium-100',
	[RiskLvl.high]: 'bg-high-100',
	[RiskLvl.low]: 'bg-low-100',
};

const WalletsPage: React.FC = () => {
	const navigate = useNavigate();

	const { data, getWallets, loading } = useWalletsService();
	const [selectedMode, setSelectedMode] = useState<DisplayMode>(
		DisplayMode.grid
	);
	const [isOpenPopUp, setIsOpenPopUp] = useState(false);

	useEffect(() => {
		getWallets();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChangeDisplayMode = (mode: DisplayMode) => {
		setSelectedMode(mode);
	};

	const goToWalletDetails = (id: string) => {
		navigate(`/wallets/${id}`);
	};

	if (loading.wallets) {
		return (
			<div className="flex justify-center items-center min-h-[600px]">
				<Progress />
			</div>
		);
	}

	return (
		<>
			<div className="flex flex-col gap-8">
				<div className="flex gap-4 2xl:gap-8 items-center flex-row justify-between">
					<div className="flex flex-row items-center">
						<DisplayModeSwitcher
							modes={[DisplayMode.grid, DisplayMode.list]}
							selectedMode={selectedMode}
							onChangeDisplayMode={handleChangeDisplayMode}
							icons={{
								grid: iconMap.tileMode,
								list: iconMap.listMode,
							}}
							title={'View'}
						/>
						<span className="text-sm text-grey-600">4 Wallets active</span>
					</div>
					<Button
						iconPosition="right"
						icon={
							<ResponsiveIcon icon={iconMap.plusIcon} className="h-5 w-5" />
						}
						onClick={() => {
							setIsOpenPopUp(true);
						}}
					>
						New Wallet
					</Button>
				</div>

				<WalletsList
					data={data.wallets}
					goToWalletDetails={goToWalletDetails}
					className={cn({
						hidden: selectedMode !== DisplayMode.list,
					})}
				/>
				<WalletsGrid
					data={data.wallets}
					className={cn({
						hidden: selectedMode !== DisplayMode.grid,
					})}
					goToWalletDetails={goToWalletDetails}
				/>

				{isOpenPopUp && (
					<NewWalletPopUp
						handleClose={() => setIsOpenPopUp(false)}
						onSuccess={() => getWallets()}
					/>
				)}
			</div>
		</>
	);
};

export default WalletsPage;
