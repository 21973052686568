export function shortenString(
	str: string,
	startLength: number = 3,
	endLength: number = 3
): string {
	if (str.length <= startLength + endLength) {
		return str;
	}
	return (
		str.substring(0, startLength) +
		'...' +
		str.substring(str.length - endLength)
	);
}

export const capitalizeFirstLetter = (input: string): string => {
	if (!input) return '';

	const words = input.trim().split(' ');

	if (words.length > 0) {
		words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
	}

	return words.join(' ');
};

export const buildExplorerLink = (
	hash: string,
	coin: string,
	network: string = ''
) => {
	coin = coin?.toLowerCase();

	if (coin === 'btc') {
		return 'https://www.blockchain.com/btc/tx/' + hash;
	} else if (coin === 'usdt' && network?.toLowerCase() === 'tron') {
		return 'https://tronscan.org/#/transaction/' + hash;
	} else {
		return 'https://etherscan.io/tx/' + hash;
	}
};
