import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import { cn } from '../../libs/cn';
import { iconMap } from '../../theme/Icons';
// import { Button } from '../atoms/Button';
import { Badge } from '../atoms/Badge';
import ResponsiveIcon from '../atoms/Icon';
import { Input } from '../atoms/Input';
import {
	MultiSelector,
	MultiSelectorContent,
	MultiSelectorItem,
	MultiSelectorList,
	MultiSelectorTrigger,
} from '../atoms/MultiSelect';
import BaseCard from '../organisms/BaseCard';

import { _cryptocurrencies } from '../../_mock';
import useSendTransactionsService from '../../controllers/transactions/service';
import { ResponsePostTransaction, RiskLvl } from '../../types/transaction';
import { RecursivePartial } from '../../types/util';
import { Button } from '../atoms/Button';
import AddRecipientDialog from './dialogs/AddRecipientDialog';
import RisksPopover from './popovers/RisksPopover';
import BackGuaranteeWidget from './widgets/BackGuaranteeWidget';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3 from 'web3';
import { formatNumberWithPrefix } from '../../utils/number';

type QuickTransactionType = {
	onSend?: () => void;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>;

const quickTransactionWidgetBgImageClass =
	'bg-[url("https://s3-alpha-sig.figma.com/img/a28b/0eb3/cbe640868d6d2a981c03acca6a32292f?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=eDj-416TXRPqYVFc~BLTsg~jXEA2MCM23vY7zYK4CRv6raAb2obX8T3RLLt1limVsqQW9q72b1yhcy1OTVzypkq75mMHZSiQc2G6p0~HumRftjFBRPnj-7Jg0lr6ezgdE3ZGVxstrPzJdc2z1gemXIm0-Uu3fa0fOFZV9H3KAjW2~l0lMxoCPvT9DmacONGGW1kd8d5m6pO15ZmB4wB84WZ-01hDpug0dEK9lZO1-zhNg9dFWrOF0HvayzCbVtW~LN67zdcsiOolMYgVzizNIoON70xr1Ru6p6s0ZUWp-7gvRuY6hit5dOUggSA1aWpor79SiKCWwh5Nzp78pHBCpQ__")]';

const CurrencyCheckBox = ({
	width,
	multiSelectorProps,
	multiSelectorTriggerProps,
	multiSelectorListProps,
	multiSelectorItemProps,
}: {
	width?: number;
	multiSelectorProps?: RecursivePartial<ComponentProps<typeof MultiSelector>>;
	multiSelectorTriggerProps?: RecursivePartial<
		ComponentProps<typeof MultiSelectorTrigger>
	>;
	multiSelectorListProps?: RecursivePartial<
		ComponentProps<typeof MultiSelectorList>
	>;
	multiSelectorItemProps?: RecursivePartial<
		ComponentProps<typeof MultiSelectorItem>
	>;
}) => {
	const currency = _cryptocurrencies.map((v) => v?.cryptocurrency as string);

	const [value, setValue] = useState([
		multiSelectorProps?.value || currency[0],
	]);

	useEffect(() => {
		if (multiSelectorProps?.onValuesChange) {
			(multiSelectorProps as any).onValuesChange(value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleValueChange = (value: [string]) => {
		const lastItem = value.pop();
		const newValue = lastItem ? [lastItem] : [];
		setValue(newValue);
		if (multiSelectorProps?.onValuesChange) {
			(multiSelectorProps as any).onValuesChange(newValue);
		}
	};

	return (
		<MultiSelector
			values={value}
			onValuesChange={handleValueChange}
			loop
			className={cn(
				'text-white w-[5.7rem] relative',
				multiSelectorProps?.className
			)}
			disabled={multiSelectorProps?.disabled}
		>
			<div className="absolute h-[28px] top-2 bg-gray-300 border-l"></div>
			<MultiSelectorTrigger
				className={cn(
					'border rounded-tl-none rounded-bl-none border-l-0 border-white',
					multiSelectorTriggerProps?.className
				)}
			/>
			<MultiSelectorContent>
				<MultiSelectorList
					className={cn(
						'bg-black w-[5.5rem] p-0 absolute top-0 right-0',
						multiSelectorListProps?.className
					)}
					style={{ width: width && width > 0 ? width : 'initial' }}
				>
					{currency.map((item, index) => (
						<MultiSelectorItem
							key={index}
							value={item}
							color={multiSelectorItemProps?.color || 'white'}
							className="gap-2"
							checkBoxClassName={cn(
								'border-white',
								multiSelectorItemProps?.checkBoxClassName
							)}
						>
							{item}
						</MultiSelectorItem>
					))}
				</MultiSelectorList>
			</MultiSelectorContent>
		</MultiSelector>
	);
};

const CurrencyConvertor = ({
	className,
	triggerClassName,
}: {
	className?: string;
	triggerClassName?: string;
}) => {
	return (
		<MultiSelector
			values={['USD']}
			onValuesChange={() => {}}
			loop
			className={cn('text-white w-[5.7rem] relative', className)}
		>
			<div className="absolute h-[28px] top-2 bg-gray-300 border-l"></div>
			<MultiSelectorTrigger
				hideArrow
				className={cn(
					'border rounded-tl-none rounded-bl-none border-l-0 border-white mt-0',
					triggerClassName
				)}
			/>
		</MultiSelector>
	);
};

interface SelectInputProps {
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: () => void;
	width?: number;
	className?: string;
	disabled?: boolean;
	currencyCheckBoxProps?: ComponentProps<typeof CurrencyCheckBox>;
}

export const SelectInput = React.forwardRef<HTMLDivElement, SelectInputProps>(
	(
		{
			value,
			onBlur,
			disabled,
			onChange,
			width,
			className,
			currencyCheckBoxProps,
		},
		ref
	) => {
		return (
			<div ref={ref} className="flex items-center sm:w-1/2">
				<Input
					className={cn(
						'border-white text-white rounded-br-none rounded-tr-none border-r-0 !text-sm min-h-[42px]',
						className
					)}
					rootClassName="w-full"
					value={value ?? ''}
					onChange={onChange}
					type="number"
					disabled={disabled}
					onBlur={onBlur}
				/>
				<CurrencyCheckBox {...currencyCheckBoxProps} width={width} />
			</div>
		);
	}
);
SelectInput.displayName = 'SelectInput';

interface CurrencyInputProps {
	value: string | undefined;
	onChange: () => void;
	className?: string;
	currencyConvertorProps?: {
		className?: string;
		triggerClassName?: string;
	};
}

export const CurrencyInput: React.FC<CurrencyInputProps> = ({
	value,
	onChange,
	className,
	currencyConvertorProps,
}) => {
	return (
		<div className="flex items-center sm:w-1/2">
			<Input
				className={cn(
					'border-white text-white rounded-br-none rounded-tr-none border-r-0 cursor-not-allowed',
					className
				)}
				rootClassName="w-full"
				value={value ? value : ''}
				onChange={onChange}
			/>
			<CurrencyConvertor
				className={currencyConvertorProps?.className}
				triggerClassName={currencyConvertorProps?.triggerClassName}
			/>
		</div>
	);
};

interface FeesBannerProps {
	hideMoneyBack: boolean;
	coin?: string;
	fee?: number;
	usdRate?: number;
}

const FeesBanner: React.FC<FeesBannerProps> = (props) => {
	const { coin, fee: bitCoin = 0, usdRate = 0 } = props;

	const icon = iconMap[coin?.toLowerCase() as keyof typeof iconMap];

	return (
		<div className="flex gap-4 sm:gap-8 text-white flex-wrap">
			<div className="flex gap-6 pt-8">
				<p className="flex gap-1 items-center">
					<ResponsiveIcon icon={iconMap.hourglass} />
					<span>Transaction Fees</span>
				</p>
				<div className="flex gap-3">
					<p className="flex gap-1 items-center">
						{icon && (
							<ResponsiveIcon icon={icon} className="h-[18px] stroke-white" />
						)}
						{!icon && !!coin && <>{coin}</>}
						<span>{formatNumberWithPrefix(bitCoin)}</span>
					</p>
					<p className="flex gap-1 items-center">
						<ResponsiveIcon icon={iconMap.dollar} />
						<span>{formatNumberWithPrefix(bitCoin * usdRate)}</span>
					</p>
				</div>
				{/* {hideMoneyBack && (
				<p className="flex gap-1 items-center">
					<ResponsiveIcon icon={iconMap.hourglass} />
					<span className="leading-5">
						Money Back <br /> Guarantee Fees{' '}
					</span>
				</p>
			)} */}
			</div>
			<div className="flex flex-col gap-6">
				{/* {hideMoneyBack && (
				<div className="flex gap-3">
					<p className="flex gap-1 items-center">
						<ResponsiveIcon icon={iconMap.bitcoin} />
						<span>0.05</span>
					</p>
					<p className="flex gap-1 items-center">
						<ResponsiveIcon icon={iconMap.dollar} />
						<span>3265.05</span>
					</p>
				</div>
			)} */}
			</div>
		</div>
	);
};

const InformationBanner = ({
	value,
	onClick,
}: {
	value: RiskLvl;
	onClick: () => void;
}) => (
	<div className="flex gap-5 text-white mb-8">
		<Badge variant={value} onClick={onClick}>
			{value}
		</Badge>
		<RisksPopover />
	</div>
);

const QuickTransaction: React.FC<QuickTransactionType> = () => {
	const [recipient, setRecipient] = useState('');
	const [currencyAmount, setCurrencyAmount] = useState('');
	const [convertedAmount, setConvertedAmount] = useState<number>(0);
	const [coin, setCoin] = useState<string>('');
	const [checked, setChecked] = useState(false);
	const [status, setStatus] = useState<RiskLvl>();
	const [width, setWidth] = useState(0);
	const [isConnecting, setIsConnecting] = useState(false);

	const { sendQuickTransaction, fetchMarketDataPrice, data, loading } =
		useSendTransactionsService();

	const isWalletConnectAndConnecting = coin !== 'BTC' && isConnecting;
	const isFormDisabled = !recipient || !currencyAmount || !coin;

	const [transactionDetails, setTransactionDetails] =
		useState<ResponsePostTransaction | null>();
	const [isTransactionDetailsLoading, setIsTransactionDetailsLoading] =
		useState(false);

	const handleSubmitTransaction = async () => {
		try {
			if (!recipient || !currencyAmount || !coin) return;

			setTransactionDetails(null);
			setIsTransactionDetailsLoading(true);
			const response = await sendQuickTransaction({
				address: recipient,
				amount: Number(currencyAmount),
				coin,
				chain: coin,
				useMbg: checked,
			});

			setStatus(
				response.data.outputs?.[0]?.risk_score?.toLowerCase() as RiskLvl
			);

			setTransactionDetails(response.data);
		} catch (error) {
			console.error(error);
		} finally {
			setIsTransactionDetailsLoading(false);
		}
	};

	const handleSubmitWallet = async () => {
		try {
			if (!transactionDetails) {
				return;
			}

			if (coin === 'BTC') {
				handleBitcoinTransaction(
					transactionDetails.input_address,
					transactionDetails.total_amount
				);
			} else {
				await handleWalletConnect(
					transactionDetails.input_address,
					transactionDetails.total_amount
				);
			}
			setTransactionDetails(null);
			setCurrencyAmount('');
			setConvertedAmount(0);
		} catch (error) {
			console.error(error);
		}
	};

	const selectRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (coin.length) {
			fetchMarketDataPrice(coin, 'USD');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [coin]);

	useEffect(() => {
		if (currencyAmount) {
			calculateConvertedAmount(Number(currencyAmount));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.marketDataPrice?.usd]);

	useEffect(() => {
		if (selectRef?.current) {
			setWidth(selectRef.current.clientWidth);

			const getwidth = () => {
				if (selectRef?.current) {
					setWidth(selectRef.current.clientWidth);
				}
			};
			window.addEventListener('resize', getwidth);
			return () => window.removeEventListener('resize', getwidth);
		}
	}, []);

	const calculateConvertedAmount = (value: number) => {
		const convertedValue = Number(value) * (data.marketDataPrice?.usd || 0);

		if (convertedValue && !isNaN(convertedValue) && convertedValue > 0) {
			setConvertedAmount(convertedValue);
		} else {
			setConvertedAmount(0);
		}
	};

	const handleRiskStatus = () => {
		return;
		// mock function to set risk status to 'critical'
		// setStatus(RiskLvl.critical);
	};

	const handleChecked = () => {
		setChecked((prev) => !prev);
	};

	// const [isError, setIsError] = useState(false);
	// const [processingTime] = useState(5);

	// const toggleErrorMessage = () => {
	// 	setIsError((prev) => !prev);
	// };

	const handleCurrencyAmountChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		calculateConvertedAmount(Number(e.target.value));
		setCurrencyAmount(e.target.value);
	};

	const handleBitcoinTransaction = (
		inputAddress: string,
		totalAmount: string
	) => {
		try {
			const bitcoinUrl = `bitcoin:${inputAddress}?amount=${totalAmount}`;
			window.location.href = bitcoinUrl;
			return true;
		} catch (error) {
			window.open('https://bitcoin.org/en/choose-your-wallet', '_blank');
			return false;
		}
	};

	const handleWalletConnect = async (
		recipient: string,
		currencyAmount: string
	) => {
		setIsConnecting(true);

		try {
			const provider = new WalletConnectProvider({
				rpc: {
					1: 'https://mainnet.infura.io/v3/028e9427f7164e61a921c0ea8d7045eb',
				},
				qrcode: true,
			});

			await provider.enable();

			const web3 = new Web3(provider as any);
			const accounts = await web3.eth.getAccounts();

			if (accounts.length === 0) {
				throw new Error('No accounts found');
			}

			const transaction = {
				from: accounts[0],
				to: recipient,
				value: web3.utils.toWei(currencyAmount, 'ether'),
			};

			await web3.eth.sendTransaction(transaction);

			provider.on('disconnect', (code: number, reason: string) => {
				console.log('Wallet disconnected:', code, reason);
				provider.disconnect();
			});

			return true;
		} catch (error) {
			console.error('WalletConnect error:', error);
			return false;
		} finally {
			setIsConnecting(false);
		}
	};

	const isCritical = status === RiskLvl.critical;
	const hideInfoPanel = isCritical || !data.quickTransaction?.data.fee;

	const isSendButtonDisabled =
		isWalletConnectAndConnecting ||
		isFormDisabled ||
		loading.quickTransaction ||
		!transactionDetails ||
		isTransactionDetailsLoading;

	return (
		<BaseCard
			title="Quick Transaction"
			// cardContentStyle="h-full"
			cardHeaderStyle="text-primary-300 mb-4"
			cardStyle={cn(
				'bg-grey-800 bg-cover bg-quick-transaction text-white',
				quickTransactionWidgetBgImageClass
			)}
			Footer={
				<div className="flex flex-col gap-10 w-full">
					{!hideInfoPanel && (
						<>
							{/* <TransactionTime
								time={processingTime}
								current={40}
								isError={isError}
								onClick={toggleErrorMessage}
							/> */}

							<FeesBanner
								hideMoneyBack={checked}
								coin={coin}
								fee={Number(data.quickTransaction?.data.fee || 0)}
								usdRate={data.marketDataPrice?.usd}
							/>
						</>
					)}

					<div className="flex items-center gap-6 mb-4">
						{!hideInfoPanel && (
							<p className="text-white">
								System will automaticly connect to your wallet
							</p>
						)}
						<Button
							className="mx-auto"
							onClick={handleSubmitWallet}
							disabled={isSendButtonDisabled}
						>
							<>
								{isWalletConnectAndConnecting ? (
									'Connecting Wallet...'
								) : (
									<>
										Send <ResponsiveIcon icon={iconMap.arrow} />
									</>
								)}
							</>
						</Button>
					</div>
				</div>
			}
		>
			<p className="font-bold">To</p>
			<div className="flex gap-3 mb-4">
				<Input
					className="border-white select-none"
					rootClassName="w-full"
					onChange={(e) => setRecipient(e.target.value)}
					value={recipient}
				/>
				<AddRecipientDialog setRecipient={setRecipient} />
			</div>
			{status && (
				<InformationBanner value={status} onClick={handleRiskStatus} />
			)}
			<div className="flex flex-col gap-3 mb-12 relative">
				<p className="text-white font-bold">Amount</p>
				<div className="flex gap-4 justify-between flex-col sm:flex-row">
					<SelectInput
						value={currencyAmount}
						onChange={handleCurrencyAmountChange}
						onBlur={handleSubmitTransaction}
						disabled={isTransactionDetailsLoading}
						currencyCheckBoxProps={{
							multiSelectorProps: {
								onValuesChange: (value: [string]) => {
									setCoin(value[0]);
									fetchMarketDataPrice(value[0], 'USD');
									handleSubmitTransaction();
								},
							},
						}}
						ref={selectRef}
						width={width}
					/>
					<CurrencyInput
						value={formatNumberWithPrefix(convertedAmount)}
						onChange={() => {}}
					/>
				</div>
			</div>

			<BackGuaranteeWidget checked={checked} onChange={handleChecked} />
		</BaseCard>
	);
};

export default QuickTransaction;

// interface TransactionTimeProps {
// 	time: number;
// 	current: number;
// 	onClick: () => void;
// 	isError: boolean;
// }

// const TransactionTime: React.FC<TransactionTimeProps> = ({
// 	time,
// 	current,
// 	isError,
// 	onClick,
// }) => {
// 	const rocessingMessage = 'Time To Complete Transaction';
// 	const errorMessage = 'Transaction Time Expired. Please Try Again.';

// 	return (
// 		<div
// 			className="flex flex-col w-full text-white text-[15px]"
// 			onClick={onClick}
// 		>
// 			{isError ? (
// 				<p className="bg-gradient-high bg-clip-text text-transparent-fill">
// 					{errorMessage}
// 				</p>
// 			) : (
// 				<p>{rocessingMessage}</p>
// 			)}

// 			<ProgressBar progress={current} type="line" isError={isError} />

// 			<div className="flex justify-between items-center text-xs mt-1">
// 				<span>0 min</span>
// 				<span>{time} min</span>
// 			</div>
// 		</div>
// 	);
// };
