import { useCallback, useState } from 'react';
import { iconMap } from '../../../../theme/Icons';
import { Button } from '../../../atoms/Button';
import SignInOption from './SignInOption';
import { useSelector } from 'react-redux';
import { AuthState } from '../../../../controllers/auth/slice';

export type TSignInOption = 'passkey' | 'digital-code';

interface SignInOptionsProps {
	onLoginFormSubmit: (selectedOption: TSignInOption) => void;
}

const SignInOptions: React.FC<SignInOptionsProps> = ({ onLoginFormSubmit }) => {
	const loading = useSelector(
		(state: { auth: AuthState }) => state.auth.loading
	);

	const [selectedOption, setSelectedOption] =
		useState<TSignInOption>('passkey');

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setSelectedOption(event.target.value as TSignInOption);
		},
		[]
	);
	return (
		<div className="flex flex-col h-full justify-between">
			<SignInOption
				selectedOption={selectedOption}
				handleChange={handleChange}
				value="passkey"
				name="sign-in-method"
				icon={iconMap.fingerprint}
				label="Passkey / Security Key"
				description="Sign in with a secure digital credential stored on your device"
			/>
			<SignInOption
				selectedOption={selectedOption}
				handleChange={handleChange}
				value="digital-code"
				name="sign-in-method"
				icon={iconMap.lock}
				label="Digital code to phone"
				description="Sign in with a temporary code sent to your phone via SMS or app"
			/>
			<div className="flex self-end">
				<Button
					size={'lg'}
					type="button"
					onClick={() => onLoginFormSubmit(selectedOption)}
					className="bg-white"
					loading={loading.login}
				>
					Continue
				</Button>
			</div>
		</div>
	);
};

export default SignInOptions;
