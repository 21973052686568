import { useNavigate } from 'react-router-dom';
import { cn } from '../../libs/cn';
import { iconMap } from '../../theme/Icons';
import { Badge } from '../atoms/Badge';
import { Button } from '../atoms/Button';
import ResponsiveIcon from '../atoms/Icon';
import BaseCard from '../organisms/BaseCard';

const MonitoredWalletsPanel: React.FC<{ data: any[] }> = ({ data }) => {
	const navigate = useNavigate();
	return (
		<BaseCard
			title="Monitored Wallets"
			subtitle={`Total: ${data.length || 0}`}
			cardContentStyle="h-[193px] overflow-hidden overflow-y-scroll styled-scroll"
			cardContentWrapperStyle="h-[240px]"
			Footer={
				<Button
					onClick={() => navigate('/my-wallets')}
					variant={'outline'}
					size={'sm'}
				>
					Manage Wallets
				</Button>
			}
		>
			<div className="flex flex-col justify-between gap-4">
				{data?.map((wallet, index) => (
					<div
						key={index}
						className={cn('flex gap-4 p-3 rounded-lg items-center')}
						style={{
							border: `1px solid var(--${wallet?.riskLevel?.toLowerCase()})`,
						}}
					>
						<div className="flex items-center gap-2">
							<ResponsiveIcon
								icon={iconMap.wallets}
								className="text-grey-600"
							/>
							<p className="text-base font-bold text-nowrap capitalize">
								{wallet.name}
							</p>
						</div>

						<p className="truncate w-[5.5rem] block grow">{wallet?.address}</p>

						<div className="text-right">
							<Badge
								className="items-center"
								variant={wallet?.riskLevel?.toLowerCase() as any}
							>
								<div className="capitalize">{wallet?.riskLevel}</div>
							</Badge>
						</div>
					</div>
				))}
			</div>
		</BaseCard>
	);
};

export default MonitoredWalletsPanel;
