import { useEffect } from 'react';
import useWalletService from '../../controllers/wallet/service';
import { iconMap } from '../../theme/Icons';
import { ResponseAsset, Wallet } from '../../types/wallets';
import { Badge } from '../atoms/Badge';
import { Button } from '../atoms/Button';
import { Card, CardContent, CardFooter } from '../atoms/Card';
import ResponsiveIcon from '../atoms/Icon';
import { Spinner } from '../atoms/Spinner';
import { cn } from '../../libs/cn';
import useSendTransactionsService from '../../controllers/transactions/service';

interface WalletCardProps {
	item: Wallet;
	bgColor?: string;
	goToWalletDetails: (id: string) => void;
	handleMigrate?: (item: Wallet, assets?: ResponseAsset[]) => void;
}

const WalletCard: React.FC<WalletCardProps> = ({
	item,
	goToWalletDetails,
	handleMigrate,
}) => {
	const {
		loading: { assets: loading },
		getWalletAssets,
		data: { assets },
	} = useWalletService();

	const { supportedCoinsList } = useSendTransactionsService();

	useEffect(() => {
		if (!item.address || !!assets || loading) return;

		getWalletAssets(item.address);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item.address]);

	return (
		<>
			<Card
				key={item.id}
				className="flex flex-col h-full hover:shadow-hover-lg hover:cursor-pointer transition-all lg:p-3 bg-grey-10"
				onClick={() => goToWalletDetails(item.id)}
			>
				<CardContent
					className={`gap-2 flex flex-col flex-grow bg-wallets-${item.riskLevel}bg rounded-[10px] p-2`}
				>
					<p className="text-xl capitalize">{item?.name}</p>
					<div
						className={cn('flex gap-2 flex-wrap relative', {
							'min-h-32': loading,
						})}
					>
						{loading && <Spinner />}
						{assets?.assets
							?.filter((asset) =>
								supportedCoinsList.includes(asset.symbol.toUpperCase())
							)
							.map((item) => (
								<div
									key={item?.symbol}
									className="bg-white p-2 rounded-full w-9 h-9 bg-cover bg-center rounded-full overflow-hidden shrink-0"
									style={{
										backgroundImage: `url(${item.image_url || '/no-image.png'})`,
									}}
								/>
							))}
					</div>
					<div>
						<p>Wallet Address</p>
						<p className="truncate font-bold">{item?.address}</p>
					</div>
					<div className="flex items-start lg:items-center gap-1 2xl:gap-2 flex-col 2xl:flex-row pt-2">
						<p>Risk Level</p>
						<Badge className="items-center bg-white" variant={item?.riskLevel}>
							{item?.riskLevel}
						</Badge>
					</div>
				</CardContent>

				<CardFooter className="flex justify-between my-6">
					<span className="text-grey-600 text-sm">
						Safely migrate your cryptocurrency to a new wallet address.
					</span>
					<Button
						// disabled={!item.canSend}
						iconPosition="right"
						icon={<ResponsiveIcon icon={iconMap.arrow} className="w-4 h-5" />}
						onClick={(event) => {
							event.stopPropagation();
							handleMigrate?.(item, assets?.assets);
						}}
					>
						Migrate
					</Button>
				</CardFooter>
				<div className="flex gap-2">
					<Button
						size={'icon'}
						variant={'icon'}
						icon={<ResponsiveIcon icon={iconMap.edit} className="w-4 h-5" />}
						className="bg-grey-table border-none"
					/>
					<Button
						size={'icon'}
						variant={'icon'}
						icon={
							<ResponsiveIcon
								icon={iconMap.delete}
								color="#7B7B7B"
								className="w-4 h-5"
							/>
						}
						className="bg-grey-table border-none"
					/>
				</div>
			</Card>
		</>
	);
};

export default WalletCard;
