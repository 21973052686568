import { ColumnDef, ColumnMeta } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useSendTransactionsService from '../../../controllers/transactions/service';
import { SendTransaction } from '../../../controllers/transactions/slice';
import { iconMap } from '../../../theme/Icons';
import { Button } from '../../atoms/Button';
import { Checkbox } from '../../atoms/Checkbox';
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '../../atoms/Form';
import ResponsiveIcon from '../../atoms/Icon';
import { Input } from '../../atoms/Input';
import { getDateTimeCell } from '../../organisms/Table/baseConfig';
import {
	BaseTable,
	FilterOptionsType,
	TypeRangeFilterData,
} from '../../organisms/Table/BaseTable';
import { CurrencyInput, SelectInput } from '../../specialized/QuickTransaction';
import { ColumnStyleType } from '../../specialized/TransactionTable/columns';
import { Card } from '../../atoms/Card';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3 from 'web3';
import { ResponsePostTransaction, RiskLvl } from '../../../types/transaction';
import { formatNumberWithPrefix } from '../../../utils/number';
import { Badge } from '../../atoms/Badge';
import { useSearchParams } from 'react-router-dom';

type SendTransactionColumn = SendTransaction;

const SendPage: React.FC = () => {
	const {
		data,
		loading,
		addDraftTransaction,
		removeDraftTransaction,
		fetchMarketDataPrice,
	} = useSendTransactionsService();

	const [searchParams] = useSearchParams();

	const defaultCoin = (searchParams?.get('coin') as string) || 'BTC';
	const defaultAmount = parseFloat(`${searchParams?.get('amount') || 0}`);

	console.log('defaultCoin', defaultCoin);

	const [selectedIds, setSelectedIds] = useState<string[]>([]);
	const [isConnectingIds, setIsConnectingIds] = useState<string[]>([]);
	const [isAddingToList, setIsAddingToList] = useState(false);

	const form = useForm<
		SendTransaction & {
			convertedAmount: number;
		}
	>({
		defaultValues: {
			useMbg: false,
			coin: defaultCoin,
			amount: defaultAmount,
		},
	});

	const [coin, amount, convertedAmount] = form.watch([
		'coin',
		'amount',
		'convertedAmount',
	]);

	const { isSubmitting, isValid } = form.formState;

	const calculateConvertedAmount = (value: number) => {
		const convertedValue = Number(value) * (data.marketDataPrice?.usd || 0);

		if (convertedValue && !isNaN(convertedValue) && convertedValue > 0) {
			form.setValue('convertedAmount', convertedValue);
		} else {
			form.setValue('convertedAmount', 0);
		}
	};

	useEffect(() => {
		if (coin.length) {
			fetchMarketDataPrice(coin, 'USD');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [coin]);

	useEffect(() => {
		calculateConvertedAmount(Number(amount || 0));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.marketDataPrice?.usd]);

	const handleBitcoinTransaction = (
		recipient: string,
		currencyAmount: string
	) => {
		try {
			const bitcoinUrl = `bitcoin:${recipient}?amount=${currencyAmount}`;
			window.location.href = bitcoinUrl;
			return true;
		} catch (error) {
			console.error(error);
			window.open('https://bitcoin.org/en/choose-your-wallet', '_blank');
			return false;
		}
	};

	const handleWalletConnect = async (
		id: string,
		recipient: string,
		currencyAmount: string
	) => {
		setIsConnectingIds([...isConnectingIds, id]);

		try {
			const provider = new WalletConnectProvider({
				rpc: {
					1: 'https://mainnet.infura.io/v3/028e9427f7164e61a921c0ea8d7045eb',
				},
				qrcode: true,
			});

			await provider.enable();

			const web3 = new Web3(provider as any);
			const accounts = await web3.eth.getAccounts();

			if (accounts.length === 0) {
				throw new Error('No accounts found');
			}

			const transaction = {
				from: accounts[0],
				to: recipient,
				value: web3.utils.toWei(currencyAmount, 'ether'),
			};

			await web3.eth.sendTransaction(transaction);

			provider.on('disconnect', (code: number, reason: string) => {
				console.log('Wallet disconnected:', code, reason);
				provider.disconnect();
			});

			return true;
		} catch (error) {
			console.error('WalletConnect error:', error);
			return false;
		} finally {
			setIsConnectingIds(isConnectingIds.filter((i) => i !== id));
		}
	};

	const handleSendSingleTransaction = async (id: string) => {
		try {
			const transaction = data.draftTransactions?.find(
				(draftTransaction) => draftTransaction.id === id
			);

			if (!transaction) return;

			if (transaction.coin === 'BTC') {
				handleBitcoinTransaction(
					transaction.response.input_address,
					transaction.response.total_amount
				);
			} else {
				handleWalletConnect(
					id,
					transaction.response.input_address,
					transaction.response.total_amount
				);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const columns: (ColumnDef<
		SendTransactionColumn,
		string | Record<string, string>
	> & {
		style?: ColumnStyleType;
		meta?: ColumnMeta<
			{
				filter?: FilterOptionsType<TypeRangeFilterData | string[]>;
			},
			unknown
		>;
	})[] = [
		{
			accessorKey: 'id',
			size: 0,
			header: '',
			cell: ({ row }) => {
				const id = row.getValue<string>('id');
				const isLoading = loading.transactionsSent.includes(id);
				return (
					<Checkbox
						checked={selectedIds.includes(id)}
						onCheckedChange={(checked) => {
							if (checked) {
								setSelectedIds([...selectedIds, id]);
							} else {
								setSelectedIds(selectedIds.filter((i) => i !== id));
							}
						}}
						color="black"
						disabled={isSubmitting || isLoading}
						iconClassNames="text-black"
					/>
				);
			},
		},
		{
			accessorKey: 'addedDate',
			header: () => <span className="block">Added</span>,
			cell: getDateTimeCell,
			size: 140,
		},
		{
			accessorKey: 'address',
			header: 'To',
			// cell: (props) => <WalletCodeCell {...props.getValue<WalletDataType>()} />,
			cell: (props) => <div>{props.getValue<string>()}</div>,
			size: 80,
		},
		{
			accessorKey: 'amount',
			header: () => 'Total Amount',
			cell: (props) => {
				const totalAmount = props.getValue<number>();
				const icon =
					iconMap[coin?.toLowerCase().trim() as keyof typeof iconMap];
				const usdRate = data.marketDataPrice?.usd || 0;

				return (
					<div className="flex gap-3 items-center justify-center">
						<div className="flex gap-2">
							<div className="flex gap-1 items-center">
								{icon && (
									<ResponsiveIcon
										icon={icon}
										className="h-[18px] aspect-square stroke-white"
									/>
								)}
								{!icon && !!coin && <>{coin}</>}
								<div>{formatNumberWithPrefix(totalAmount)}</div>
							</div>
							<div className="flex gap-1 items-center">
								<ResponsiveIcon icon={iconMap.dollar} />
								<span>{formatNumberWithPrefix(totalAmount * usdRate)}</span>
							</div>
						</div>
					</div>
				);
			},
			size: 120,
		},
		{
			accessorKey: 'response',
			header: () => <span>Total Fees</span>,
			cell: (props) => {
				const transaction = props.getValue<ResponsePostTransaction>();
				const icon = iconMap[coin?.toLowerCase() as keyof typeof iconMap];
				const fee = Number(transaction.fee);
				const usdRate = data.marketDataPrice?.usd || 0;

				return (
					<div className="flex gap-2 items-center">
						<ResponsiveIcon icon={iconMap.hourglass} />
						<div className="flex gap-2 items-center">
							<div className="flex gap-1 items-center">
								{icon && (
									<ResponsiveIcon
										icon={icon}
										className="h-[18px] stroke-white"
									/>
								)}
								{!icon && !!coin && <>{coin}</>}
								<span>{formatNumberWithPrefix(fee)}</span>
							</div>
							<div className="flex gap-1 items-center">
								<ResponsiveIcon icon={iconMap.dollar} />
								<span>{formatNumberWithPrefix(fee * usdRate)}</span>
							</div>
						</div>
					</div>
				);
			},
		},
		{
			accessorKey: 'response',
			header: () => <span>Risk Level</span>,
			cell: (props) => {
				const transaction = props.getValue<ResponsePostTransaction>();
				const riskLevel =
					transaction.outputs?.[0]?.risk_score?.toLowerCase() as RiskLvl;

				return <Badge variant={riskLevel}>{riskLevel}</Badge>;
			},
		},
		{
			accessorKey: 'useMbg',
			header: () => <span>Money Back Guarantee</span>,
			cell: (props) => <div>{props.getValue<boolean>() ? 'Yes' : 'No'}</div>,
			size: 130,
		},
		{
			accessorKey: 'actions',
			header: '',
			size: 140,
			cell: ({ row }) => {
				const id = row.getValue<string>('id');
				const isLoading = loading.transactionsSent.includes(id);
				return (
					<div className="w-full h-[52px] flex items-center gap-x-2">
						<Button
							className="ml-auto"
							onClick={() => handleSendSingleTransaction(id)}
							loading={isLoading || isConnectingIds.includes(id)}
						>
							Send <ResponsiveIcon icon={iconMap.arrow} />
						</Button>
						<Button
							variant="outline"
							onClick={() => removeDraftTransaction(id)}
							disabled={isLoading}
						>
							<ResponsiveIcon
								className="w-[25px] h-[25px]"
								icon={iconMap.delete}
							/>
						</Button>
					</div>
				);
			},
		},
	];

	const handleAddToList = async () => {
		setIsAddingToList(true);
		await addDraftTransaction(form.getValues());
		setIsAddingToList(false);
	};

	const validSelectedIds = selectedIds.filter((selectedId) =>
		data.draftTransactions?.some(
			(draftTransaction) => draftTransaction.id === selectedId
		)
	);

	console.log('data', data.draftTransactions);

	return (
		<div className="flex flex-col gap-y-4 h-full">
			<FormProvider {...form}>
				<div className="px-3 w-full space-y-4">
					<div className="font-roboto text-sm	text-grey-600">
						Add new items to the queue and send all transactions when
						you&apos;re ready. <br /> After sending, all transactions will
						appear in Transactions list.
					</div>
					<div className="rounded-xl bg-grey-10 shadow-lg flex px-[29px] py-[22px] justify-between items-center gap-x-6">
						<FormField
							rules={{
								required: 'This field is required',
							}}
							control={form.control}
							name="address"
							render={({ field }) => (
								<FormItem className="space-y-1 grow w-1/2">
									<div className="flex flex-col gap-y-4">
										<FormLabel>To</FormLabel>
										<FormControl>
											<Input
												className="py-2 border-black"
												disabled={isSubmitting}
												{...field}
												onChange={field.onChange}
												value={field.value ?? ''}
											/>
										</FormControl>
									</div>
									<FormMessage />
								</FormItem>
							)}
						/>
						<div className="flex flex-col gap-y-4 !space-y-[0]">
							<FormLabel>Amount</FormLabel>
							<FormItem className="flex gap-x-2 !space-y-[0px">
								<FormField
									rules={{
										required: 'This field is required',
									}}
									control={form.control}
									name="amount"
									render={({ field }) => (
										<FormControl>
											<div className="flex gap-4 justify-between flex-col sm:flex-row">
												<SelectInput
													value={form.getValues('amount')?.toString()}
													className="!text-grey-800 !border-grey-800"
													onChange={(e) => {
														field.onChange(e.target.value);
														calculateConvertedAmount(Number(e.target.value));
													}}
													currencyCheckBoxProps={{
														multiSelectorProps: {
															value: form.getValues('coin')?.toString(),
															disabled: !!data?.draftTransactions?.length,
															className: '!text-grey-800',
															onValuesChange: (values: string[]) => {
																form.setValue('coin', values?.[0]);
																fetchMarketDataPrice(values[0], 'USD');
															},
														},
														multiSelectorTriggerProps: {
															className: '!border-grey-800',
														},
														multiSelectorListProps: {
															className: '!bg-white',
														},
														multiSelectorItemProps: {
															checkBoxClassName: '!border-grey-800',
															color: 'black',
														},
													}}
												/>
												<CurrencyInput
													className="!text-grey-800 !border-grey-800"
													currencyConvertorProps={{
														className: 'text-grey-800 border-grey-800',
														triggerClassName: 'text-grey-800 border-grey-800',
													}}
													value={`${convertedAmount || ''}`}
													onChange={() => {}}
												/>
											</div>
										</FormControl>
									)}
								/>
							</FormItem>
						</div>
						<div>
							<div>Secure your Crypto with Money Back Guarantee</div>
							<FormField
								control={form.control}
								name="useMbg"
								render={({ field }) => (
									<FormItem className="space-y-1">
										<div className="flex flex-col gap-y-4">
											<FormControl>
												<div className="flex items-center gap-2">
													<Checkbox
														checked={field.value}
														onCheckedChange={(checked) =>
															field.onChange(checked)
														}
														color="black"
														disabled={isSubmitting}
														iconClassNames="text-black"
														// className="border-white data-[state=checked]:bg-white"
													/>
													<p className="font-bold">I’ll Take It!</p>
												</div>
											</FormControl>
										</div>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<Button
							size="sm"
							disabled={!isValid}
							onClick={async (e) => {
								e.preventDefault();
								await handleAddToList();
								form.reset();
							}}
							loading={isSubmitting || isAddingToList}
						>
							Add To List
						</Button>
					</div>
				</div>
			</FormProvider>
			<div className="grow">
				<BaseTable
					columns={columns}
					data={data.draftTransactions}
					isLoading={false}
					error={null}
					enableExpanding={false}
				/>
			</div>
			{!!validSelectedIds.length && (
				<Card className="w-full mt-auto">
					<Button
						className="ml-auto flex"
						// onClick={() => {
						// 	sendTransactions(validSelectedIds);
						// }}
						loading={loading.transactionsSent.length > 0}
						disabled // TODO: disable batch send
					>
						Send ({validSelectedIds.length}/{data.draftTransactions.length}){' '}
						<ResponsiveIcon icon={iconMap.arrow} />
					</Button>
				</Card>
			)}
		</div>
	);
};

export default SendPage;
