import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { notificationActions, NotificationState } from './slice';

type NotificationServiceOperators = NotificationState & {
	getNotifications: () => void;
};

const useNotificationService = (): Readonly<NotificationServiceOperators> => {
	const dispatch = useAppDispatch();
	const notificationState = useAppSelector((state) => state.notification);

	return {
		data: notificationState.data,
		loading: notificationState.loading,
		error: notificationState.error,
		getNotifications: useCallback(() => {
			dispatch(notificationActions.fetchNotificationsRequest());
		}, [dispatch]),
	};
};

export default useNotificationService;
