import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { ResponseWalletAsset, Wallet } from '../../types/wallets';

export type WalletsState = {
	data: {
		wallets: Wallet[];
		assets: ResponseWalletAsset[];
	};
	loading: {
		wallets: boolean;
		assets: string[];
	};
	error: {
		wallets: string | null;
		assets: { address: string; error: string }[];
	};
};

const initialState: WalletsState = {
	data: {
		wallets: [],
		assets: [],
	},
	loading: {
		wallets: false,
		assets: [],
	},
	error: {
		wallets: null,
		assets: [],
	},
};

const walletsSlice = createSlice({
	name: 'wallets',
	initialState,
	reducers: {
		fetchWalletsRequest: (state) => {
			state.loading.wallets = true;
			state.error.wallets = null;
		},
		fetchWalletsSuccess: (state, action: PayloadAction<Wallet[]>) => {
			state.loading.wallets = false;
			state.data.wallets = action.payload;
		},
		fetchWalletsFailure: (state, action: PayloadAction<string>) => {
			state.loading.wallets = false;
			state.error.wallets = action.payload;
		},
		fetchWalletAssetsRequest: (state, action: PayloadAction<string>) => {
			state.loading.assets = [...state.loading.assets, action.payload];
			state.error.assets = state.error.assets.filter(
				(wallet) =>
					wallet.address.toLowerCase() !== action.payload.toLowerCase()
			);
		},
		fetchWalletAssetsSuccess: (
			state,
			action: PayloadAction<ResponseWalletAsset>
		) => {
			state.loading.assets = state.loading.assets.filter(
				(wallet) =>
					wallet.toLowerCase() !== action.payload.wallet_address.toLowerCase()
			);
			state.data.assets = [...state.data.assets, action.payload];
		},
		fetchWalletAssetsFailure: (
			state,
			action: PayloadAction<{ address: string; error: string }>
		) => {
			state.loading.assets = state.loading.assets.filter(
				(address) =>
					address.toLowerCase() !== action.payload.address.toLowerCase()
			);
			state.error.assets = [...state.error.assets, action.payload];
		},
	},
});

export const walletsActions = {
	fetchWalletsRequest: walletsSlice.actions.fetchWalletsRequest,
	fetchWalletsSuccess: walletsSlice.actions.fetchWalletsSuccess,
	fetchWalletsFailure: walletsSlice.actions.fetchWalletsFailure,
	fetchWalletAssetsRequest: walletsSlice.actions.fetchWalletAssetsRequest,
	fetchWalletAssetsSuccess: walletsSlice.actions.fetchWalletAssetsSuccess,
	fetchWalletAssetsFailure: walletsSlice.actions.fetchWalletAssetsFailure,
};

// Reducer
export default walletsSlice.reducer;
