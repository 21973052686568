import { Avatar, AvatarFallback, AvatarImage } from '../../atoms/Avatar';
import ResponsiveIcon from '../../atoms/Icon';
import { iconMap } from '../../../theme/Icons';
import { Button } from '../../atoms/Button';
import { cn } from '../../../libs/cn';
import { useEffect, useMemo, useState } from 'react';
import { NotificationPopUp } from '../NotificationPopUp/NotificationPopUp';
import useNotificationService from '../../../controllers/notification/service';
import useAuthService from '../../../controllers/auth/service';

type HeaderProps = {
	title?: string;
	className?: string;
};

const Header: React.FC<HeaderProps> = ({ title, className }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { data, loading, getNotifications } = useNotificationService();
	const { data: authData } = useAuthService();

	useEffect(() => {
		getNotifications();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const userInitials = useMemo(() => {
		return [authData.user?.first_name, authData.user?.last_name]
			.map((name) => name?.[0]?.toUpperCase())
			.join('');
	}, [authData.user?.first_name, authData.user?.last_name]);

	return (
		<div className="flex gap-x-4 p-4 font-bold ml-auto justify-between w-full items-center">
			<p className="sm:text-lg md:text-3xl font-roboto-condensed text-grey-600 font-normal">
				{title}
			</p>
			{
				// Test Button
			}
			<w3m-button></w3m-button>
			<div className="flex gap-2 sm:gap-4 md:gap-x-4 items-center">
				<Button
					variant={'icon'}
					size={'icon'}
					className={cn('border-none bg-white', className)}
				>
					<ResponsiveIcon
						icon={iconMap.bell}
						badgeStyles="bg-critical"
						badgeText={data?.notification?.unread_notifications}
						onClick={() => setIsOpen(!isOpen)}
					/>
				</Button>
				<Avatar className="w-11 h-11 sm:w-auto sm:h-auto">
					<AvatarImage src="null" />
					<AvatarFallback className="bg-gradient-primary w-9 h-9">
						{userInitials}
					</AvatarFallback>
				</Avatar>
			</div>
			{isOpen && (
				<NotificationPopUp
					data={data.notification}
					loading={loading.notification}
					handleClose={() => setIsOpen(false)}
				/>
			)}
		</div>
	);
};

export { Header };
