import { API_BASE_MEMBER_URL } from '../../config';
import { axiosInstanceApi } from '../../services/axios';
import { ResponseData, ResponsePaginated } from '../../types/common';
import { ResponseNotificationData } from '../../types/notification';

export const getNotificationsApi = async (
	params: { pn?: number; ps?: number } = { pn: 1, ps: 10 }
) => {
	const queryParams = {
		pn: `${params?.pn}`,
		ps: `${params?.ps}`,
	};
	const queryString = new URLSearchParams(queryParams).toString();

	try {
		const response = await axiosInstanceApi.get<
			ResponseData<ResponsePaginated<ResponseNotificationData>>
		>(`${API_BASE_MEMBER_URL}/v1/member/notification?${queryString}`, {});
		return response.data.data;
	} catch (error) {
		console.log('error', error);
	}
};
