import BaseCard from '../../organisms/BaseCard';
import { getChartBgColor } from '../../../utils/getChartColor';
import TransactionStatusChart from '../../organisms/Chart/TransactionStatusChart';
import { Spinner } from '../../atoms/Spinner';

const TransactionsInRisk: React.FC<{
	data: any[];
	totalTransaction: number;
	isLoading: boolean;
}> = ({ data, totalTransaction, isLoading }) => {
	const updatedRiskData = data
		.map((item) => {
			const { color } = getChartBgColor(item?.code);
			return { ...item, color };
		})
		?.filter((item) => !!item.value);

	return (
		<BaseCard
			title="Transaction Status & Distribution"
			subtitle={`Total transactions: ${totalTransaction}`}
			cardContentStyle="h-[240px]"
			cardContentWrapperStyle="h-[240px]"
			cardStyle="relative"
		>
			{isLoading && <Spinner />}
			{data && <TransactionStatusChart data={updatedRiskData} />}
		</BaseCard>
	);
};

export default TransactionsInRisk;
