export const formatNumber = (num: number, decimals: number = 2) => {
	if (num < 1000) {
		return formatNumberWithPrefix(num, '', decimals, decimals);
	} else if (num >= 1000 && num < 1000000) {
		return formatNumberWithPrefix(num, '', decimals, decimals) + 'K';
	} else if (num >= 1000000) {
		return formatNumberWithPrefix(num / 1000000, '', decimals, decimals) + 'M';
	} else return formatNumberWithPrefix(num, '', decimals, decimals);
};

export const formatNumberWithPrefix = (
	value: number,
	prefix = '',
	maxDecimals: number = 2,
	minDecimals: number = 2
) => {
	const actualDecimals = value.toString().split('.')[1]?.length || 0;

	const hasSignificantDigits =
		actualDecimals > minDecimals && Number(value.toFixed(maxDecimals)) !== 0;

	const formattedNumber = value.toLocaleString(undefined, {
		minimumFractionDigits: minDecimals,
		maximumFractionDigits: hasSignificantDigits ? maxDecimals : minDecimals,
	});

	return `${prefix}${formattedNumber}`;
};
