import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { ResponseData } from '../../types/common';
import {
	ResponsePostTransaction,
	ResponseSupportedCoinData,
} from '../../types/transaction';

export interface SendTransaction {
	address: string;
	amount: number;
	coin: string;
	chain: string;
	useMbg: boolean;
}

export type SendTransactionWithResponse = SendTransaction & {
	id: string;
	addedDate: number;
	response: ResponsePostTransaction;
};

export type SendTransactionsState = {
	data: {
		draftTransactions: SendTransactionWithResponse[];
		transactionsSent: SendTransactionWithResponse[] | null;
		quickTransaction: ResponseData<ResponsePostTransaction> | null;
		marketDataPrice?: Record<string, number> | null;
		supportedCoins?: ResponseSupportedCoinData | null;
	};
	loading: {
		transactionsSent: string[];
		quickTransaction: boolean;
		marketDataPrice: boolean;
		supportedCoins: boolean;
	};
	error: {
		transactionsSent: string | null;
		marketDataPrice: string | null;
		quickTransaction: string | null;
		supportedCoins: string | null;
	};
};

const initialState: SendTransactionsState = {
	data: {
		draftTransactions: [],
		transactionsSent: [],
		quickTransaction: null,
		supportedCoins: null,
	},
	loading: {
		transactionsSent: [],
		quickTransaction: false,
		marketDataPrice: false,
		supportedCoins: false,
	},
	error: {
		transactionsSent: null,
		marketDataPrice: null,
		quickTransaction: null,
		supportedCoins: null,
	},
};

const sendTransactionsSlice = createSlice({
	name: 'sendTransactions',
	initialState,
	reducers: {
		sendTransactionsRequest: (
			state,
			action: PayloadAction<
				(SendTransaction & {
					id: string;
				})[]
			>
		) => {
			state.loading.transactionsSent = [
				...state.loading.transactionsSent,
				...action.payload.map((t) => t.id),
			];
			state.error.transactionsSent = null;
		},
		sendTransactionsSuccess: (
			state,
			action: PayloadAction<SendTransactionWithResponse[]>
		) => {
			state.loading.transactionsSent = [];
			state.data.draftTransactions = state.data.draftTransactions.filter(
				(transaction) =>
					!action.payload?.length ||
					!action.payload?.some(
						(sentTransaction) => sentTransaction.id === transaction.id
					)
			);
			state.data.transactionsSent = action.payload;
		},
		sendTransactionsFailure: (state, action: PayloadAction<string[]>) => {
			state.loading.transactionsSent = state.loading.transactionsSent.filter(
				(t) => !action.payload.includes(t)
			);
			// state.error.transactionsSent = action.payload;
		},
		addDraftTransaction: (
			state,
			action: PayloadAction<SendTransactionWithResponse>
		) => {
			state.data.draftTransactions = [
				...(state.data.draftTransactions || []),
				action.payload,
			];
		},
		removeDraftTransaction: (state, action: PayloadAction<string>) => {
			state.data.draftTransactions = state.data.draftTransactions.filter(
				(transaction) => transaction.id !== action.payload
			);
		},

		getMarketDataPriceRequest: (
			state,
			_action: PayloadAction<{
				fsym: string;
				tsyms: string;
			}>
		) => {
			state.loading.marketDataPrice = true;
			state.error.marketDataPrice = null;
		},
		getMarketDataPriceSuccess: (
			state,
			action: PayloadAction<ResponseData<{ usd: number }>>
		) => {
			state.loading.marketDataPrice = false;
			state.error.marketDataPrice = null;
			state.data.marketDataPrice = action.payload.data;
		},
		getMarketDataPriceFailure: (state, action: PayloadAction<string>) => {
			state.loading.marketDataPrice = false;
			state.error.marketDataPrice = action.payload;
		},

		sendQuickTransactionRequest: (
			state,
			_action: PayloadAction<SendTransaction>
		) => {
			state.data.quickTransaction = null;
			state.loading.quickTransaction = true;
			state.error.quickTransaction = null;
		},
		sendQuickTransactionSuccess: (
			state,
			action: PayloadAction<ResponseData<ResponsePostTransaction>>
		) => {
			state.loading.quickTransaction = false;
			state.data.quickTransaction = action.payload;
		},
		sendQuickTransactionFailure: (state, action: PayloadAction<string>) => {
			state.loading.quickTransaction = false;
			state.error.quickTransaction = action.payload;
		},

		getSupportedCoinsRequest: (state) => {
			state.loading.supportedCoins = true;
			state.error.supportedCoins = null;
		},
		getSupportedCoinsSuccess: (
			state,
			action: PayloadAction<ResponseSupportedCoinData>
		) => {
			state.loading.supportedCoins = false;
			state.error.supportedCoins = null;
			state.data.supportedCoins = action.payload;
		},
		getSupportedCoinsFailure: (state, action: PayloadAction<string>) => {
			state.loading.supportedCoins = false;
			state.error.supportedCoins = action.payload;
		},
	},
});

export const sendTransactionsActions = {
	sendTransactionsRequest:
		sendTransactionsSlice.actions.sendTransactionsRequest,
	sendTransactionsSuccess:
		sendTransactionsSlice.actions.sendTransactionsSuccess,
	sendTransactionsFailure:
		sendTransactionsSlice.actions.sendTransactionsFailure,

	sendQuickTransactionRequest:
		sendTransactionsSlice.actions.sendQuickTransactionRequest,
	sendQuickTransactionSuccess:
		sendTransactionsSlice.actions.sendQuickTransactionSuccess,
	sendQuickTransactionFailure:
		sendTransactionsSlice.actions.sendQuickTransactionFailure,

	getMarketDataPriceRequest:
		sendTransactionsSlice.actions.getMarketDataPriceRequest,
	getMarketDataPriceSuccess:
		sendTransactionsSlice.actions.getMarketDataPriceSuccess,
	getMarketDataPriceFailure:
		sendTransactionsSlice.actions.getMarketDataPriceFailure,
	//
	addTransaction: sendTransactionsSlice.actions.addDraftTransaction,
	removeTransaction: sendTransactionsSlice.actions.removeDraftTransaction,
	//
	getSupportedCoinsRequest:
		sendTransactionsSlice.actions.getSupportedCoinsRequest,
	getSupportedCoinsSuccess:
		sendTransactionsSlice.actions.getSupportedCoinsSuccess,
	getSupportedCoinsFailure:
		sendTransactionsSlice.actions.getSupportedCoinsFailure,
};

// Reducer
export default sendTransactionsSlice.reducer;
