import { Button } from '../atoms/Button';
import migrate from '../../assets/images/migrate.png';
import { ResponseAsset } from '../../types/wallets';
import { Badge } from '../atoms/Badge';
import { RiskLvl } from '../../types/transaction';
import { formatNumber } from '../../utils/number';
import { useNavigate } from 'react-router-dom';
import useSendTransactionsService from '../../controllers/transactions/service';

interface WalletPopUpProps {
	walletName: string;
	riskLevel: RiskLvl;
	assets?: ResponseAsset[];
	walletAddress: string;
	handleClose: () => void;
}

const WalletPopUp: React.FC<WalletPopUpProps> = ({
	walletName,
	riskLevel,
	assets,
	walletAddress,
	handleClose,
}) => {
	const navigate = useNavigate();

	const { supportedCoinsList } = useSendTransactionsService();

	return (
		<div
			className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-10"
			onClick={handleClose}
		>
			<div className="bg-white rounded-lg shadow-lg p-2.5 min-w-50 relative">
				<div
					className={`bg-wallets-${riskLevel}bg rounded-lg px-6 py-[18px] space-y-[18px]`}
				>
					<div className="text-3xl font-normal font-roboto-condensed">
						{walletName}
					</div>

					<div className="text-gray-600 font-roboto font-normal text-sm">
						Safely migrate your cryptocurrency to a new wallet address.
					</div>
					<div className="space-y-5 max-h-[300px] overflow-y-auto">
						{assets
							?.filter((asset) =>
								supportedCoinsList.includes(asset.symbol.toUpperCase())
							)
							?.map((item) => {
								return (
									<div
										className=" mb-4 flex items-center justify-between"
										key={item.symbol}
									>
										<div className="flex gap-x-2.5 items-center">
											<div
												className="bg-grey-10 p-2 rounded-full w-9 h-9 bg-cover bg-center rounded-full overflow-hidden shrink-0"
												style={{
													backgroundImage: `url(${item.image_url || '/no-image.png'})`,
												}}
											/>
											<div className="flex items-center gap-x-2.5 font-roboto font-normal text-sm">
												<div>
													{formatNumber(item?.amount)} {item.symbol}
												</div>
												<div>
													{formatNumber(item?.amount)} {item.symbol}
												</div>
											</div>
										</div>

										<Button
											variant="icon"
											size="icon"
											className=" w-9 h-9"
											onClick={() => navigate('/send')}
										>
											<img src={migrate} alt="Migrate Logo" />
										</Button>
									</div>
								);
							})}
					</div>
					<div className="font-roboto  text-sm">
						<div className="font-normal">Wallet Address</div>
						<div className="font-bold">{walletAddress}</div>
					</div>
					<div className="flex gap-x-2.5 font-roboto  text-sm items-center">
						<div className="font-normal">Risk Level</div>
						<Badge className="items-center bg-white" variant={riskLevel}>
							{riskLevel}
						</Badge>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WalletPopUp;
