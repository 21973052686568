import { type SagaIterator } from '@redux-saga/core';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
	getMarketDataPrice,
	getSupportedCoins,
	postQuickTransactionApi,
	postTransactionApi,
} from './api';
import {
	SendTransaction,
	sendTransactionsActions,
	SendTransactionWithResponse,
} from './slice';
import { ResponseData } from '../../types/common';
import {
	ResponsePostTransaction,
	ResponseSupportedCoinData,
} from '../../types/transaction';

// Worker Sagas
function* postTransaction(
	action: PayloadAction<(SendTransaction & { id: string })[]>
): SagaIterator {
	try {
		const response: SendTransactionWithResponse[] = yield call(
			postTransactionApi,
			action.payload
		);
		yield put(sendTransactionsActions.sendTransactionsSuccess(response));
	} catch (error) {
		yield put(
			sendTransactionsActions.sendTransactionsFailure(
				action.payload.map((t) => t.id)
			)
		);
	}
}

function* postQuickTransaction(
	action: PayloadAction<SendTransaction>
): SagaIterator {
	try {
		const response: ResponseData<ResponsePostTransaction> = yield call(
			postQuickTransactionApi,
			action.payload
		);
		yield put(sendTransactionsActions.sendQuickTransactionSuccess(response));
	} catch (error) {
		yield put(
			sendTransactionsActions.sendQuickTransactionFailure(
				'Failed to send quick transaction'
			)
		);
	}
}

function* fetchMarketDataPrice(
	action: PayloadAction<{
		fsym: string;
		tsyms: string;
	}>
): SagaIterator {
	try {
		const response = yield call(getMarketDataPrice, action.payload);
		yield put(sendTransactionsActions.getMarketDataPriceSuccess(response));
	} catch (error) {
		yield put(
			sendTransactionsActions.getMarketDataPriceFailure(
				'Failed to load transactions'
			) // set custom error message
		);
	}
}

function* fetchSupportedCoins(): SagaIterator {
	try {
		const response: ResponseData<ResponseSupportedCoinData> =
			yield call(getSupportedCoins);
		yield put(sendTransactionsActions.getSupportedCoinsSuccess(response.data));
	} catch (error) {
		yield put(
			sendTransactionsActions.getMarketDataPriceFailure(
				'Failed to load supported coins'
			)
		);
	}
}

// Watcher Saga
function* walletWatcherSaga(): SagaIterator {
	yield takeLatest(
		sendTransactionsActions.sendTransactionsRequest.type,
		postTransaction
	);
	yield takeLatest(
		sendTransactionsActions.getMarketDataPriceRequest.type,
		fetchMarketDataPrice
	);
	yield takeLatest(
		sendTransactionsActions.sendQuickTransactionRequest.type,
		postQuickTransaction
	);
	yield takeLatest(
		sendTransactionsActions.getSupportedCoinsRequest.type,
		fetchSupportedCoins
	);
}

export default walletWatcherSaga;
