import { useCallback, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ResponseWalletAsset } from '../../types/wallets';
import { getWalletAssetsApi } from '../wallets/api';
import { WalletsState } from '../wallets/slice';
import { WalletState, walletActions } from './slice';

// TODO: move assets to wallet slice when endpoint is available
type WalletServiceOperators = {
	data: WalletState['data'] & {
		assets?: ResponseWalletAsset;
	};
	loading: WalletState['loading'] & {
		assets: boolean;
	};
	error: WalletState['error'];
} & {
	getWalletById: (id: string) => void;
	getTransactionsByWalletId: (id: string) => void;
	getWalletAssets: (id: string) => void;
	createNewWallet: (
		walletAddress: string,
		walletChain: string,
		walletName: string
	) => void;
};

const useWalletService = (): Readonly<WalletServiceOperators> => {
	const dispatch = useAppDispatch();
	const walletState = useAppSelector((state) => state.wallet as WalletState);
	const walletsState = useAppSelector((state) => state.wallets as WalletsState);
	const [assetsLoading, setAssetsLoading] = useState(false);
	const [assets, setAssets] = useState<ResponseWalletAsset>();

	const transactions = useMemo(() => {
		return walletState.data.transactions?.map((transaction) => ({
			...transaction,
			chainId: walletState?.data?.walletById?.cryptocurrencies?.[0]?.code,
		}));
	}, [
		walletState.data.transactions,
		walletState.data?.walletById?.cryptocurrencies,
	]);

	return {
		data: {
			...walletState.data,
			transactions,
			assets,
		},
		loading: {
			...walletState.loading,
			assets: assetsLoading,
		},
		error: walletState.error,
		getWalletById: useCallback(
			(id: string) => {
				dispatch(
					walletActions.fetchWalletByIdRequest({
						id,
						wallets: walletsState.data.wallets,
					})
				);
			},
			[dispatch, walletsState.data.wallets]
		),
		// getWalletById: useCallback(
		// 	(id: string) => {
		// 		return walletsState.data.wallets.find(
		// 			(wallet) => wallet.address.toLowerCase() === id.toLowerCase()
		// 		);
		// 	},
		// 	[walletsState.data.wallets]
		// ),
		getWalletAssets: useCallback(
			async (id: string) => {
				if (assets || assetsLoading) return;
				try {
					setAssetsLoading(true);
					const response = await getWalletAssetsApi(id);
					setAssets(response);
				} finally {
					setAssetsLoading(false);
				}
			},
			[assets, assetsLoading]
		),
		getTransactionsByWalletId: useCallback(
			(id: string) => {
				dispatch(walletActions.fetchTransactionsByWalletIdRequest(id));
			},
			[dispatch]
		),
		createNewWallet: useCallback(
			(walletAddress, walletChain, walletName) => {
				dispatch(
					walletActions.createNewWalletRequest({
						walletAddress,
						walletChain,
						walletName,
					})
				);
			},
			[dispatch]
		),
	};
};

export default useWalletService;
