import { type SagaIterator } from '@redux-saga/core';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getCurrentUser, loginApi, verifyLoginApi, mfaApi } from './api';

import { authActions } from './slice';
import { getSecureToken, setSecureToken } from '../../utils/tokenStorage';
import { MfaOption } from '../../types/auth';

// Worker Sagas
export function* loginWorker(
	action: PayloadAction<{
		email: string;
		password: string;
		signInOption: string;
	}>
): SagaIterator {
	try {
		const { mfaToken, oobCode, selectedMfaOption } = yield call(
			loginApi,
			action.payload
		);
		yield put(
			authActions.loginSuccess({
				mfaToken,
				oobCode,
				selectedMfaOption,
			})
		);
	} catch (error: unknown) {
		yield put(authActions.loginFailure('Failed to log in'));
	}
}

export function* verifyLoginWorker(
	action: PayloadAction<{
		code: string[];
		mfaToken: string;
		oobCode?: string | null;
	}>
): SagaIterator {
	try {
		const { accessToken, idToken } = yield call(verifyLoginApi, action.payload);
		yield put(authActions.verificationSuccess({ accessToken, idToken }));
		setSecureToken(accessToken, 'accessToken');
	} catch (error: unknown) {
		yield put(authActions.verificationFailure('Failed to verify'));
	}
}

export function* refreshTokenWorker(): SagaIterator {
	try {
		const accessToken = getSecureToken('accessToken'); //temporary while waiting for CSRF Token mechanism

		if (!accessToken) {
			throw new Error('Missing Refresh Token');
		}
		yield put(authActions.refreshTokenSuccess({ accessToken }));
	} catch (error: unknown) {
		yield put(authActions.refreshTokenFailure());
	}
}

export function* sendMfaWorker(
	action: PayloadAction<{
		mfaToken: string;
		selectedMfaOption?: MfaOption;
	}>
): SagaIterator {
	try {
		const response = yield call(mfaApi, action.payload);
		yield put(authActions.sendMfaRequestSuccess(response.data));
	} catch (error) {
		yield put(authActions.sendMfaRequestFailure());
	}
}

export function* currentUserWorker(): SagaIterator {
	try {
		const response = yield call(getCurrentUser);
		yield put(authActions.currentUserSuccess(response.data));
	} catch (error) {
		yield put(authActions.currentUserFailure());
	}
}

// Watcher Saga
function* authWatcherSaga(): SagaIterator {
	yield takeEvery(authActions.loginRequest, loginWorker);
	yield takeEvery(authActions.verificationRequest, verifyLoginWorker);
	yield takeEvery(authActions.refreshTokenRequest, refreshTokenWorker);
	yield takeEvery(authActions.sendMfaRequest, sendMfaWorker);
	yield takeEvery(authActions.currentUserRequest, currentUserWorker);
}

export default authWatcherSaga;
