import { _wallets } from '../../_mock';
import { API_BASE_WALLET_URL } from '../../config';
import { walletTransactionsResponseMapper } from '../../mapper/wallet';
import { axiosInstanceApi } from '../../services/axios';
import { ResponseWalletTransaction } from '../../types/wallets';
import { ResponseData, ResponsePaginated } from './../../types/common';

export const getWalletByIdApi = (id: number) => {
	return new Promise((resolve) => {
		const mockData = _wallets.find((wallet) => wallet.id === id);

		setTimeout(() => {
			resolve({ data: mockData });
		}, 1000);
	});
};

export const getTransactionsByWalletIdApi = async (
	walletId: string,
	params: {
		pn?: number;
		ps?: number;
		sort_order?: string;
		sort_by?: string;
	} = {
		pn: 1,
		ps: 10,
	}
) => {
	try {
		const queryParams = {
			...params,
			pn: `${params?.pn}`,
			ps: `${params?.ps}`,
			wallet_id: walletId,
		};
		const queryString = new URLSearchParams(queryParams).toString();

		const response = await axiosInstanceApi.get<
			ResponseData<ResponsePaginated<ResponseWalletTransaction>>
		>(
			`${API_BASE_WALLET_URL}/v1/wallet/transactions?${queryString.toString()}`
		);

		const formattedResponse = response.data.data.data.map((transaction) =>
			walletTransactionsResponseMapper(transaction)
		);

		return {
			...response.data,
			data: formattedResponse,
		};
	} catch (error) {
		console.log('error', error);
	}
};

export const postNewWallet = async ({
	walletAddress,
	walletChain,
	walletName,
}: {
	walletAddress: string;
	walletChain: string;
	walletName: string;
}) => {
	const response = await axiosInstanceApi.post<any>(
		`${API_BASE_WALLET_URL}/v1/wallet`,
		{
			wallet_address: walletAddress,
			wallet_chain: walletChain.toLowerCase(),
			wallet_name: walletName,
		}
	);
	return response.data;
};
