import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { _cryptocurrencies } from '../../_mock';
import addWalletLogo from '../../assets/images/addwallet.png';
import { ResponseNotificationData } from '../../types/notification';
import { Button } from '../atoms/Button';
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '../atoms/Form';
import { Input } from '../atoms/Input';
import { MultiSelectDropdown } from '../atoms/MultiselectDropdown';
import useWalletService from '../../controllers/wallet/service';
import { useEffect } from 'react';

type NewWalletPopUpProps = {
	data?: ResponseNotificationData | null;
	handleClose?: () => void;
	onSuccess?: () => void;
};

type FormValues = {
	address: string;
	nickName: string;
	chain: string[];
};

const NewWalletPopUp: React.FC<NewWalletPopUpProps> = ({
	onSuccess,
	handleClose,
}) => {
	const { createNewWallet, loading, data } = useWalletService();

	const form = useForm<FormValues>({
		// resolver: zodResolver(formSchema),
		mode: 'onChange',
		defaultValues: {
			address: '',
			nickName: '',
			chain: [],
		},
	});

	const { reset, handleSubmit } = form;

	const onClose = () => {
		reset();
		handleClose && handleClose();
	};

	const onSubmit: SubmitHandler<FormValues> = (data) => {
		createNewWallet(data.address, data.chain[0], data.nickName);
	};

	useEffect(() => {
		if (data.newWallet) {
			onSuccess && onSuccess();
			onClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.newWallet]);

	return (
		<div
			className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-10"
			onClick={onClose}
		>
			<div
				className="bg-white rounded-lg shadow-lg p-8 relative w-full max-w-lg space-y-8"
				onClick={(e) => e.stopPropagation()}
			>
				<div className="flex border-b border-b-[#A7D0C7] pb-2 gap-x-2.5 items-center">
					<div>
						<img src={addWalletLogo} className="w-9	h-9" alt="Add Wallet Logo" />
					</div>
					<div className="font-roboto-condensed text-3xl	font-normal">
						Add New Wallet
					</div>
				</div>

				<FormProvider {...form}>
					<form
						onSubmit={handleSubmit(onSubmit)}
						className="flex flex-col gap-y-8"
					>
						<FormField
							control={form.control}
							name="address"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Address</FormLabel>
									<FormControl>
										<Input
											{...field}
											autoComplete="off"
											placeholder="bc1d42UNb54eBiGm0qEM0h6r2h8n532to9jtp186ns"
										/>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<div className="flex gap-x-4 justify-between">
							<FormField
								control={form.control}
								name="nickName"
								render={({ field }) => (
									<FormItem className="grow">
										<FormLabel>Nick Name</FormLabel>
										<FormControl>
											<Input
												{...field}
												className="w-full"
												autoComplete="off"
												placeholder="Kucoin"
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="chain"
								render={({ field }) => (
									<FormItem className="w-1/3">
										<FormLabel>Chain</FormLabel>
										<FormControl>
											<MultiSelectDropdown
												className="max-h-64 overflow-y-auto"
												selectedValue={field.value || []}
												setSelectedValue={(values) => {
													const lastItem = values?.pop();
													field.onChange([lastItem]);
												}}
												values={_cryptocurrencies.map(
													(item) => item.cryptocurrency as string
												)}
												triggerClassName="!border-input flex-1"
												placeholder="Select Chain"
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>

						<Button type="submit" disabled={loading.newWallet}>
							Add Wallet
						</Button>
					</form>
				</FormProvider>
			</div>
		</div>
	);
};

export { NewWalletPopUp };
