import { CellContext, ColumnDef, ColumnMeta } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { _cryptocurrencies } from '../../../_mock';

import { iconMap } from '../../../theme/Icons';
import {
	Transaction,
	TransactionStatus,
	WalletDataType,
} from '../../../types/transaction';

import { buildExplorerLink, shortenString } from '../../../utils/string';
import { Button } from '../../atoms/Button';
import ResponsiveIcon from '../../atoms/Icon';
import { Status, StatusProps } from '../../atoms/Status';

import { cn } from '../../../libs/cn';
import {
	getDateTimeCell,
	getGuaranteeCell,
	getRiskLevelandFactorsCell,
	getRiskLevelCell,
	getTotalAmountCell,
	WalletCodeCell,
} from '../../organisms/Table/baseConfig';
import {
	FilterOptionsType,
	FilterTypeEnum,
	TypeRangeFilterData,
} from '../../organisms/Table/BaseTable';
import { getPauseEligibility } from '../../../controllers/transactions/api';

const StatusCell: <T>(
	props: CellContext<T, string | Record<string, string>>
) => JSX.Element = (props) => {
	const value = props.getValue<string>();
	const status = value.toLocaleLowerCase()?.replace(' ', '');
	const [loading, setLoading] = useState<boolean>(false);
	const [isPausable, setIsPausable] = useState<boolean>(false);

	const transactionId = (props.row.original as any).id;

	const shouldInquirePauseEligibility = [
		TransactionStatus.rejected.toLowerCase(),
		TransactionStatus.completed.toLowerCase(),
	].includes(value.toLowerCase());

	const [transactionStatus, setTransactionStatus] = useState<TransactionStatus>(
		status as TransactionStatus
	);

	const handlePause = () => {
		setTransactionStatus(TransactionStatus.paused);
	};

	const handleResend = () => {
		setTransactionStatus(TransactionStatus.transferring);
	};

	const fetchPauseEligibility = async () => {
		try {
			setLoading(true);
			const response = await getPauseEligibility(transactionId);
			setIsPausable(!!response);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!loading && shouldInquirePauseEligibility && transactionId) {
			fetchPauseEligibility();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldInquirePauseEligibility, transactionId]);

	return (
		<div className="flex justify-between transition-all w-44">
			{value === TransactionStatus.unknown ? (
				<div className="flex items-center group gap-1">
					<ResponsiveIcon
						icon={iconMap.hourglass}
						className="h-5 w-5 transform transition-transform duration-500 ease-in-out group-hover:rotate-180"
					/>
					<p>Waiting for User Action</p>
				</div>
			) : transactionStatus === TransactionStatus.paused ? (
				<Button
					className="hover:gap-2 bg-grey-table px-3 py-0 rounded-2xl border-transparent text-sm"
					onClick={handleResend}
					icon={<ResponsiveIcon icon={iconMap.refresh} />}
					iconPosition="right"
				>
					Resend
				</Button>
			) : (
				<div
					className={cn(
						'flex gap-1 items-center capitalize',
						transactionStatus === TransactionStatus.transferring &&
							'flex-row-reverse items-center justify-center w-full group-hover:opacity-50'
					)}
				>
					<Status variant={transactionStatus as StatusProps['variant']} />
					{transactionStatus === TransactionStatus.transferring
						? `${transactionStatus}...`
						: transactionStatus}
				</div>
			)}
			{isPausable && (
				<div className="hidden group-hover:block">
					<Button
						className="hover:gap-2 bg-grey-table px-3 py-0 rounded-2xl border-transparent text-sm"
						onClick={handlePause}
					>
						{value === TransactionStatus.paused ? (
							<>
								Resume
								<ResponsiveIcon icon={iconMap.pause} />
								{/* TODO: replace with resume icon when available */}
							</>
						) : (
							<>
								Pause
								<ResponsiveIcon icon={iconMap.pause} />
							</>
						)}
					</Button>
				</div>
			)}
		</div>
	);
};

///////////////

export type ColumnType = ColumnDef<
	Transaction,
	string | Record<string, string>
> & {
	style?: ColumnStyleType | undefined;
};

export type ColumnStyleType = {
	width?: string | number;
};

export const dashboardColumns: (ColumnDef<
	Transaction,
	string | Record<string, string>
> & {
	style?: ColumnStyleType;
})[] = [
	{
		accessorKey: 'dateTime',
		header: 'Sent',
		cell: getDateTimeCell,
		size: 120,
	},
	{
		accessorKey: 'from',
		header: 'From',
		cell: (props) => <WalletCodeCell {...props.getValue<WalletDataType>()} />,
		size: 80,
	},
	{
		accessorKey: 'to.code',
		header: 'To',
		cell: (props) => (
			<WalletCodeCell
				code={props.getValue<string>()}
				className={cn({
					truncate: !props.row.getIsExpanded(),
					'break-words': props.row.getIsExpanded(),
				})}
			/>
		),

		size: 80,
	},
	{
		accessorKey: 'hash',
		header: 'Hash#',
		cell: (props) => {
			// const value = props.getValue<string>();
			// console.log('dashboard page', props.row.original);
			// return <span>{value?.length ? shortenString(value) : '-'}</span>;
			const value = props.getValue<string>();
			const coinName = props.row.original.coinName?.toLowerCase();
			const chainId = props.row.original.chainId?.toLowerCase();
			const hashLink = buildExplorerLink(value, coinName, chainId);
			return (
				<a
					href={hashLink}
					rel="noreferrer"
					target="_blank"
					className="underline"
				>
					{value?.length ? shortenString(value) : '-'}
				</a>
			);
		},
		size: 100,
	},
	{
		accessorKey: 'totalAmount',
		header: () => <span className="whitespace-nowrap">Total Amount</span>,
		cell: getTotalAmountCell,
		size: 100,
	},
	{
		accessorKey: 'riskLevel',
		header: () => <span className="block truncate">Risk Level & Factors</span>,
		cell: getRiskLevelandFactorsCell,
		size: 100,
	},
	{
		accessorKey: 'status',
		header: () => <span className="block">Status</span>,
		cell: StatusCell,
		size: 220,
	},
	{
		accessorKey: 'moneyBackGuarantee',
		header: () => <div className="flex-1 truncate">Money Back Guarantee</div>,
		cell: getGuaranteeCell,
		size: 120,
	},
	{
		accessorKey: 'actions',
		header: '',
		size: 20,
		cell: ({ row }) => (
			<div
				className="cursor-pointer h-[52px] flex items-center"
				onClick={() => row.toggleExpanded()}
			>
				<ResponsiveIcon
					icon={iconMap.outlinedArrowDown}
					className={row.getIsExpanded() ? 'rotate-180' : ''}
				/>
				{/* {<RenderExpandedRowContent row={row} />} */}
			</div>
		),
	},
];

export const transactionColumns: (ColumnDef<
	Transaction,
	string | Record<string, string>
> & {
	style?: ColumnStyleType;
	meta?: ColumnMeta<
		{
			filter?: FilterOptionsType<TypeRangeFilterData | string[]>;
		},
		unknown
	>;
})[] = [
	{
		accessorKey: 'dateTime',
		header: () => <span className="block">Sent</span>,
		cell: getDateTimeCell,
		size: 140,
		enableSorting: true,
	},
	{
		accessorKey: 'from',
		header: 'From',
		cell: (props) => <WalletCodeCell {...props.getValue<WalletDataType>()} />,
		enableSorting: true,
		size: 100,
		meta: {
			filter: {
				type: FilterTypeEnum.SingleValue,
				data: ['Wallet 1', 'Wallet 2', 'Wallet 3', 'Wallet 4', 'Wallet 5'],
			},
		},
	},
	{
		accessorKey: 'to.code',
		header: 'To',
		cell: (props) => {
			return (
				<WalletCodeCell
					code={props.getValue<string>()}
					className={cn({
						truncate: !props.row.getIsExpanded(),
						'break-words': props.row.getIsExpanded(),
					})}
				/>
			);
		},
		enableSorting: true,
		size: 80,
		meta: {
			filter: {
				type: FilterTypeEnum.SingleValue,
				data: ['Wallet 1', 'Wallet 2', 'Wallet 3', 'Wallet 4', 'Wallet 5'],
			},
		},
	},
	{
		accessorKey: 'hash',
		header: 'Hash#',
		cell: (props) => {
			const value = props.getValue<string>();
			const coinName = props.row.original.coinName?.toLowerCase();
			const chainId = props.row.original.chainId?.toLowerCase();
			const hashLink = buildExplorerLink(value, coinName, chainId);
			return (
				<a
					href={hashLink}
					rel="noreferrer"
					target="_blank"
					className="underline"
				>
					{value?.length ? shortenString(value) : '-'}
				</a>
			);
			// const value = props.getValue<string>();
			// console.log('transactions page', props.row.original);
			// if (!value) {
			// 	return <div className="text-center w-full">-</div>;
			// }
			// return <span className="underline">{shortenString(value)}</span>;
		},
		size: 100,
		enableSorting: true,
	},
	{
		accessorKey: 'totalAmount',
		header: () => 'Total Amount',
		cell: getTotalAmountCell,
		enableSorting: true,
		size: 120,
		meta: {
			expandableContent: true,
			filter: {
				type: FilterTypeEnum.range,
				data: {
					dropdownItems: _cryptocurrencies.map<string>((item) =>
						item.cryptocurrency.toString()
					),
					range: [0, 100],
				},
			},
		},
	},
	{
		accessorKey: 'riskLevel',
		header: 'Risk Level',
		cell: getRiskLevelCell,
		enableSorting: true,
		size: 120,
	},
	{
		accessorKey: 'riskFactor',
		header: () => <span className="whitespace-nowrap">Risk Factors</span>,
		cell: (props) => {
			const value = props.getValue<string>();
			return (
				<div
					className={cn({
						'w-full text-center': !value,
					})}
				>
					{value || '-'}
				</div>
			);
		},
		size: 120,
		enableSorting: true,
	},
	{
		accessorKey: 'status',
		header: 'Status',
		cell: StatusCell,
		enableSorting: true,
		size: 220,
	},
	{
		accessorKey: 'moneyBackGuarantee',
		header: () => <span>Money Back Guarantee</span>,
		cell: getGuaranteeCell,
		size: 130,
		enableSorting: true,
	},
	{
		accessorKey: 'actions',
		header: '',
		enableSorting: false,
		size: 20,
		cell: ({ row }) => (
			<div
				className="cursor-pointer h-[52px] flex items-center"
				onClick={() => row.toggleExpanded()}
			>
				<ResponsiveIcon
					icon={iconMap.outlinedArrowDown}
					className={row.getIsExpanded() ? 'rotate-180' : ''}
				/>
				{/* {<RenderExpandedRowContent row={row} />} */}
			</div>
		),
	},
];
