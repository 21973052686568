import { useEffect, useMemo, useState } from 'react';
import useDashboardDataService from '../../controllers/dashboard/service';
import { iconMap } from '../../theme/Icons';
import { Button } from '../atoms/Button';
import ResponsiveIcon from '../atoms/Icon';
import { Progress } from '../atoms/Progress';
import { transactionColumns } from '../specialized/TransactionTable/columns';

import { useNavigate } from 'react-router-dom';
import { EmptyPlaceholder } from '../atoms/EmptyPlaceholder';
import { TransactionTable } from '../specialized/TransactionTable/TransactionTable';

const PAGE_SIZE = 10;

const TransactionsPage: React.FC = () => {
	const navigate = useNavigate();
	const { data, error, loading, loadTransactions } = useDashboardDataService();
	const [page, setPage] = useState(1);
	useEffect(() => {
		loadTransactions({
			pn: page,
			ps: PAGE_SIZE,
		});
	}, [loadTransactions, page]);

	const paginationData = useMemo(() => {
		const pagination = data.pagination;
		if (!pagination) {
			return null;
		}
		const firstItemIndex = (pagination.current_page - 1) * PAGE_SIZE + 1;
		const lastItemIndex = firstItemIndex + data.transactions.length - 1;
		const maxPage = Math.ceil(pagination.total_items / PAGE_SIZE);
		return {
			label: `Showing ${firstItemIndex}-${lastItemIndex} of ${pagination.total_items}`,
			maxPage,
		};
	}, [data.pagination, data.transactions.length]);

	if (loading.transactions) {
		return (
			<div className="flex justify-center items-center min-h-[600px]">
				<Progress />
			</div>
		);
	}

	return (
		<>
			{data.transactions.length === 0 && (
				<EmptyPlaceholder className="flex-1">
					<EmptyPlaceholder.Icon
						icon={iconMap.noTransactionShadow}
						size={100}
					/>
					<EmptyPlaceholder.Description description="You haven't made any transactions yet" />

					<Button className="mt-8" onClick={() => navigate('/send')}>
						Make Any Transaction
						<ResponsiveIcon icon={iconMap.transactions} className="h-8 w-8" />
					</Button>
				</EmptyPlaceholder>
			)}

			<div className="flex flex-col gap-6 flex-1">
				<div className="flex flex-col gap-5 h-full">
					<div className="flex gap-4 items-center justify-between flex-col lg:flex-row p-4">
						<div className="text-grey-600 text-sm">
							<span className="pr-2">{paginationData?.label}</span>
							<span
								className="underline cursor-pointer"
								onClick={() =>
									setPage(Math.min(page + 1, paginationData?.maxPage || 1))
								}
							>
								Next
							</span>
						</div>
						<Button
							iconPosition="right"
							icon={
								<ResponsiveIcon
									icon={iconMap.outlinedArrowDown}
									className="h-5 w-5"
								/>
							}
							onClick={() => {}}
						>
							Export Report
						</Button>
					</div>

					<div className="ml-[-12px]">
						<TransactionTable
							columns={transactionColumns}
							data={data.transactions}
							isLoading={loading.transactions}
							error={error.transactions}
							type="extended"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default TransactionsPage;
