import { CellContext } from '@tanstack/react-table';
import {
	CRYPTOCURRENCY,
	cryptocurrencySignMap,
	CURRENCY,
	currencySignMap,
} from '../../../types/currency';
import { BackGuarantee, TotalAmountType } from '../../../types/transaction';

import { parseDateTime } from '../../../utils/date';
import { formatNumber } from '../../../utils/number';
import { Badge, BadgeProps } from '../../atoms/Badge';
import { Guarantee, GuaranteeProps } from '../../atoms/Guarantee';
import ResponsiveIcon from '../../atoms/Icon';
import { iconMap } from '../../../theme/Icons';
import { Button } from '../../atoms/Button';
import { cn } from '../../../libs/cn';

export const getDateTimeCell: <T>(
	props: CellContext<T, string | Record<string, string>>
) => JSX.Element = (props) => {
	const value = props.getValue<string>();
	const { date, time } = parseDateTime(value) ?? { date: '-', time: '-:-' };
	return (
		<div className="al">
			<span className="pr-1">{date}</span>
			<span>{time}</span>
		</div>
	);
};

export const WalletCodeCell = ({
	code,
	wallet,
	className,
}: {
	code: string;
	wallet?: string;
	className?: string;
}) => {
	return (
		<div className="al flex flex-row">
			{wallet && (
				<span className="text-sm text-black pr-2 truncate">{wallet}</span>
			)}
			{code && (
				<span
					className={`w-[5rem] block ${wallet ? '' : 'text-black'} ${className}`}
				>
					{code}
				</span>
			)}
			{!code && !wallet && <span className="text-center w-full">-</span>}
		</div>
	);
};

const TotalAmountCell = ({
	mainCurrency,
	secondaryCurrency,
}: {
	mainCurrency: { value: string; sign: string };
	secondaryCurrency: { value: string; sign: string };
}) => {
	return (
		<div>
			<span className="pr-2 text-black">
				<span className="font-extrabold pr-1">{mainCurrency.sign}</span>
				{mainCurrency.value}
			</span>
			<span className="text-sm text-black">
				<span className="pr-1 ">{secondaryCurrency.sign}</span>
				{secondaryCurrency.value}
			</span>
		</div>
	);
};

export const getTotalAmountCell: <T>(
	props: CellContext<T, string | Record<string, string>>
) => JSX.Element = (props) => {
	const { from, to, fee } = props.cell.getValue<TotalAmountType>();

	const mainCurrency = {
		value: from.value ? formatNumber(+from.value) : '-',
		sign: cryptocurrencySignMap[from.unit as CRYPTOCURRENCY],
	};
	const secondaryCurrency = {
		value: to.value ? formatNumber(+to.value) : '-',
		sign: currencySignMap[to.unit as CURRENCY],
	};

	return (
		<>
			<TotalAmountCell
				mainCurrency={mainCurrency}
				secondaryCurrency={secondaryCurrency}
			/>
			{props.row.getIsExpanded() && (
				<div>
					<span className="text-grey-600 font-roboto-condensed text-sm">
						Transaction Fees
					</span>
					<div className="text-black">{fee || '-'}</div>
				</div>
			)}
		</>
	);
};

export const getRiskLevelandFactorsCell: <T>(
	props: CellContext<T, string | Record<string, string>>
) => JSX.Element = (props) => {
	const value = props.getValue<string>();
	return (
		<div className="flex flex-row align-baseline">
			<Badge
				variant={value as BadgeProps['variant']}
				className="capitalize w-[81px] justify-center"
			>
				{value}
			</Badge>
			<span className="pl-2 truncate flex items-center justify-center">
				1 factors
			</span>
		</div>
	);
};

export const getRiskLevelCell: <T>(
	props: CellContext<T, string | Record<string, string>>
) => JSX.Element = (props) => {
	const value = props.getValue<string>();
	return (
		<Badge
			variant={value as BadgeProps['variant']}
			className="capitalize w-[81px] justify-center"
		>
			{value || '-'}
		</Badge>
	);
};

export const getGuaranteeCell: <T>(
	props: CellContext<T, string | Record<string, string>>
) => JSX.Element = (props) => {
	const value = props.getValue<string>();

	return (
		<div className="space-y-2">
			<div className="flex flex-row">
				<div
					className={cn('flex items-center gap-3 capitalize text-black pr-2')}
				>
					{value || '-'}
					<Guarantee variant={value as GuaranteeProps['variant']} />
				</div>
				{value === BackGuarantee.yes && (
					<div className="text-sm flex gap-1 bg-grey-table px-3 py-0 rounded-2xl items-center">
						<span>Details</span>
						<ResponsiveIcon icon={iconMap.file} className="w-4 h-4" />
					</div>
				)}
			</div>
			{props.row.getIsExpanded() && (
				<Button variant={'outline'} disabled>
					Make a Claim
				</Button>
			)}
		</div>
	);
};
