import { RiskLvl, Transaction } from '../types/transaction';
import { RecursivePartial } from '../types/util';
import { ResponseWallet, Wallet } from '../types/wallets';
import { ResponseWalletTransaction } from './../types/wallets';

export const walletResponseMapper = (
	wallet: ResponseWallet
): RecursivePartial<Wallet> => {
	return {
		id: wallet.wallet_id,
		name: wallet.wallet_name,
		address: wallet.wallet_id,
		cryptocurrencies: [
			{
				code: wallet.wallet_chain,
				name: wallet.wallet_chain,
				amount: 32,
				canSend: true,
			},
		],
		riskLevel: wallet.summary.risk_score as RiskLvl,
		riskFactors: wallet.summary.risk_factors,
		canSend: false,
	};
};

const getRiskDetails = (walletTransaction: ResponseWalletTransaction) => {
	const partyType =
		walletTransaction.transaction_type === 'out' ? 'receiver' : 'sender';

	return {
		riskLevel: walletTransaction[`${partyType}_risk_score`],
		riskFactor: walletTransaction[`${partyType}_risk_factors`]?.length ?? 0,
	};
};

export const walletTransactionsResponseMapper = (
	walletTransaction: ResponseWalletTransaction
): RecursivePartial<Transaction> => {
	const date = new Date(walletTransaction.created_at);
	const dateTime = date.toLocaleString();

	const { riskLevel, riskFactor } = getRiskDetails(walletTransaction);

	return {
		id: walletTransaction.unique_id,
		dateTime,
		from: {
			code: walletTransaction.sender,
			wallet: '',
			walletId: 1,
		},
		proxy: '',
		to: {
			code: walletTransaction.receiver,
			wallet: walletTransaction.receiver,
			walletId: 1,
		},
		type: walletTransaction.transaction_type,
		hash: walletTransaction.tx_hash,
		totalAmount: {
			from: {
				value: walletTransaction.amount,
				unit: '',
			},
			to: {
				value: walletTransaction.usd_value,
				unit: '',
			},
			fee: walletTransaction.usd_value.toString(),
		},
		riskLevel,
		riskFactor,
		status: '',
		moneyBackGuarantee: 'Yes',
		coinName: walletTransaction.coin_name,
	};
};
