import { API_BASE_TRANSACTION_URL, API_BASE_WALLET_URL } from '../../config';
import { axiosInstanceApi } from '../../services/axios';
import { ResponseData } from '../../types/common';
import {
	ResponsePostTransaction,
	ResponseSupportedCoinData,
} from '../../types/transaction';
import { SendTransaction } from './slice';

export const postTransactionApi = async (data: SendTransaction[]) => {
	const results = await Promise.allSettled(
		data.map((transaction, index) =>
			axiosInstanceApi
				.post<ResponseData<ResponsePostTransaction>>(
					`${API_BASE_TRANSACTION_URL}/v1/transaction`,
					{
						outputs: [
							{
								address: transaction.address,
								amount: Number(transaction.amount),
							},
						],
						coin: transaction.coin,
						chain: transaction.chain,
						use_mbg: transaction.useMbg,
					}
				)
				.then((response) => ({
					success: true,
					index,
					data: response.data,
					transaction,
				}))
				.catch(() => ({ success: false, index, transaction }))
		)
	);

	const successfulResults = results.filter(
		(
			result
		): result is PromiseFulfilledResult<{
			success: true;
			index: number;
			data: ResponseData<ResponsePostTransaction>;
			transaction: SendTransaction;
		}> => result.status === 'fulfilled' && result.value.success
	);

	const successfulTransactions = successfulResults.map((result) => ({
		...result.value.transaction,
		response: result.value.data.data,
	}));

	const failedTransactions = data.filter(
		(_, index) =>
			!successfulResults.map((result) => result.value.index).includes(index)
	);

	if (failedTransactions.length > 0) {
		console.log('Failed transactions:', failedTransactions);
	}

	return successfulTransactions;
};

export const postQuickTransactionApi = async (data: SendTransaction) => {
	const response = await axiosInstanceApi.post<
		ResponseData<ResponsePostTransaction>
	>(`${API_BASE_TRANSACTION_URL}/v1/transaction`, {
		outputs: [
			{
				address: data.address,
				amount: Number(data.amount),
			},
		],
		coin: data.coin,
		chain: data.chain,
		use_mbg: data.useMbg,
	});

	return response.data;
};

export const getMarketDataPrice = async (
	params: {
		fsym?: string;
		tsyms?: string;
	} = {}
) => {
	const queryString = new URLSearchParams(params).toString();
	try {
		const response = await axiosInstanceApi.get<
			ResponseData<Record<string, number>>
		>(`${API_BASE_WALLET_URL}/v1/marketdata/price?${queryString}`, {});

		return response.data;
	} catch (error) {
		console.log('error', error);
	}
};

export const getSupportedCoins = async () => {
	try {
		const response = await axiosInstanceApi.get<
			ResponseData<ResponseSupportedCoinData>
		>(`${API_BASE_TRANSACTION_URL}/v1/transaction/supported-coin`);
		return response.data;
	} catch (error) {
		console.log('error', error);
	}
};

export const getPauseEligibility = async (transactionId: string) => {
	try {
		const response = await axiosInstanceApi.get<ResponseData<boolean>>(
			`${API_BASE_TRANSACTION_URL}/v1/transaction/${transactionId}/pause-eligibility`
		);
		return response.data;
	} catch (error) {
		console.log('error', error);
	}
};
